import { Game, type TGames } from "@lobby/core/entities/game";
import { useIntlT, useTranslate } from "@lobby/ocb-intl";
import { clientCategories } from "@shared/const";
import { isFavouritesCategory, isRecommendedCategory, useAuth } from "@shared/lib";
import { useSearch } from "@tanstack/react-router";
import { useMemo } from "react";

type TTranslateFunction = ReturnType<typeof useTranslate>["$t"];

type TGroupedGameListResult = {
  type: string;
  label: string | undefined;
  id: string | number;
  games: TGames;
};

function getSearchedGames(
  games: TGames,
  searchedGame: string,
  $t: TTranslateFunction,
): TGroupedGameListResult {
  return {
    type: "search",
    label: $t({ defaultMessage: "Search Result" }),
    id: clientCategories.searchResults.id,
    games: games.filter((game) => game.name.toLowerCase().includes(searchedGame)).slice(0, 20),
  };
}

function getFavouriteGames(games: TGames, $t: TTranslateFunction): TGroupedGameListResult {
  return {
    type: "favourites",
    label: $t({ defaultMessage: "Favourites" }),
    id: clientCategories.favourites.id,
    games: games.filter((game) => game.isFavourite),
  };
}

function getRecommendedGames(
  games: TGames,
  recommendedGameIds: Set<number>,
  $t: TTranslateFunction,
): TGroupedGameListResult {
  return {
    type: "recommended",
    label: $t({ defaultMessage: "Recommended" }),
    id: clientCategories.recommended.id,
    games: games.filter(({ id }) => recommendedGameIds.has(id)),
  };
}

function getCategoryGames(games: TGames, cid: number, lang: string): TGroupedGameListResult {
  return {
    type: "category",
    label: Game.getCategoryNameById(cid, lang),
    id: cid,
    games: games.filter((game) => game.categories.includes(cid)),
  };
}

function getProviderGames(games: TGames, pid: number): TGroupedGameListResult {
  return {
    type: "provider",
    label: Game.getProviderNameById(pid),
    id: pid,
    games: games.filter((game) => game.providerId === pid),
  };
}

function getCategorizedGames(games: TGames, lang: string) {
  return games.reduce((acc, game) => {
    for (const id of game.categories) {
      const category = acc.find((item) => item.id === id);
      if (category) {
        category.games.push(game);
        continue;
      }

      acc.push({
        type: "category",
        label: Game.getCategoryNameById(id, lang),
        id,
        games: [game],
      });
    }

    return acc;
  }, [] as TGroupedGameListResult[]);
}

function sortGames(games: TGroupedGameListResult[], order: number[]) {
  return games.sort((a, b) => order.indexOf(Number(a.id)) - order.indexOf(Number(b.id)));
}

export function useGroupedGameList(): TGroupedGameListResult[] {
  const { cid, pid, game: searchedGame } = useSearch({ strict: false });
  const { isAuth } = useAuth();

  const { $t } = useTranslate();
  const {
    intl: { locale, defaultLocale },
  } = useIntlT();
  const lang = locale || (defaultLocale as any);

  const { data: games } = Game.useList();
  const { data: recommendedGamesData } = Game.useRecommendedGames();
  const { data: categoriesList } = Game.useCategoriesList();

  const recommendedGameIds = recommendedGamesData?.gamesIds;

  const favouriteGames = useMemo<TGroupedGameListResult>(() => {
    if (!games || !isAuth) return {} as TGroupedGameListResult;
    return getFavouriteGames(games, $t);
  }, [$t, games, isAuth]);

  const recommendedGames = useMemo<TGroupedGameListResult>(() => {
    if (!games || !recommendedGameIds) return {} as TGroupedGameListResult;
    return getRecommendedGames(games, new Set(recommendedGameIds), $t);
  }, [$t, games, recommendedGameIds]);

  return useMemo(() => {
    if (!games) {
      return [];
    }

    if (searchedGame) {
      return [getSearchedGames(games, searchedGame, $t)];
    }

    if (cid) {
      if (isFavouritesCategory(cid)) {
        return [favouriteGames];
      }

      if (isRecommendedCategory(cid)) {
        return [recommendedGames];
      }

      return [getCategoryGames(games, cid, lang)];
    }

    if (pid) {
      return [getProviderGames(games, pid)];
    }

    const sortOrder = (categoriesList ?? []).map((category) => category.id);
    const categorizedGames = getCategorizedGames(games, lang);

    return [favouriteGames, recommendedGames, ...sortGames(categorizedGames, sortOrder)];
  }, [games, favouriteGames, recommendedGames, categoriesList, cid, pid, searchedGame, lang]);
}
