import { ensureUserIsAuthenticated, validateSearchProfileSection } from "@app/lib";
import { redirectToIndexCategory } from "@app/lib/helpers";
import { loadFortuneWheelSettings } from "@app/lib/loaders";
import { SuccessModal } from "@features/success-modal";
import { WarningModal } from "@features/warning-modal";
import { ContactsModal } from "@modals/contacts-modal/contacts-modal";
import { TakeBonusModal } from "@modals/take-bonus/take-bonus-modal";
import { ErrorModal } from "@shared/ui/error-modal/error-modal";
import type { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Navigate, Outlet, createRootRouteWithContext, defer } from "@tanstack/react-router";
import { ErrorComponent } from "@widget/error-component";
import { ProfileModal, type TProfileSection } from "@widget/profile-modal";

type GlobalSearch = {
  modal?: string;
  cid: number;
  pid: number;
  game: string;
  redirect: string;
  profileSection?: TProfileSection;
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
  onError: console.error,
  errorComponent: ErrorComponent,
  beforeLoad: async ({ location }) => {
    if (!(await ensureUserIsAuthenticated())) return;

    if (location.pathname === "/sign-in") {
      await redirectToIndexCategory();
    }
  },
  loader: async ({ context }) => defer(loadFortuneWheelSettings(context.queryClient)),
  notFoundComponent: () => <Navigate to="/" replace />,
  validateSearch: (search: Record<string, unknown>): GlobalSearch => ({
    modal: search.modal as string,
    cid: search.cid as number,
    pid: search.pid as number,
    game: search.game as string,
    redirect: search.redirect as string,
    profileSection: validateSearchProfileSection(search),
  }),
});

function RootComponent() {
  return (
    <>
      <ProfileModal />
      <TakeBonusModal />
      <ContactsModal />
      <SuccessModal />
      <WarningModal />
      <ErrorModal />
      <div id="message-container" />

      <Outlet />
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </>
  );
}
