import { useContext } from "react";

import { MiniGameContext } from "./mini-game-context-provider";

export const useMiniGameContext = () => {
  const context = useContext(MiniGameContext);
  if (!context) {
    throw new Error("maybe the application is not wrapped in useMiniGameContext Component");
  }

  return context;
};
