import { createFileRoute } from "@tanstack/react-router";

import { isLobbyDemoMode, redirectIfAuthenticatedUserRequired } from "@app/lib";
import { redirectToIndexCategory } from "@app/lib/helpers";
import { HomePage } from "@pages/home";

export const Route = createFileRoute("/")({
  beforeLoad: async ({ cause }) => {
    !isLobbyDemoMode() && (await redirectIfAuthenticatedUserRequired());

    cause === "enter" && (await redirectToIndexCategory());
  },
  component: HomePage,
  onError: console.error,
});
