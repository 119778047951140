import { createFileRoute } from "@tanstack/react-router";

import { isLobbyDemoMode, redirectIfAuthenticatedUserRequired } from "@app/lib";
import { HomePage } from "@pages/home";

export const Route = createFileRoute("/casino")({
  beforeLoad: async () => !isLobbyDemoMode() && (await redirectIfAuthenticatedUserRequired()),
  component: HomePage,
  onError: console.error,
});
