import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

import { SVGIcon } from "@shared/ui";

import type { PropsWithChildren } from "react";

interface INavigationBackMobileProps {
  className?: string;
  onClick: VoidFunction;
}

export function NavigationBackMobile({
  className,
  children,
  onClick,
}: PropsWithChildren<INavigationBackMobileProps>) {
  const { $t } = useTranslate();
  return (
    <div className={clsx(className, "bg-athens-gray rounded-xs p-3 dark:bg-outer-space")}>
      <div className="font-bold text-lg leading-5 uppercase">{children}</div>
      <button
        className="bg-white rounded-xs flex items-center justify-between text-keppel font-bold text-sm uppercase mt-2 p-3 h-9 w-full dark:bg-bright-gray dark:text-java"
        type="button"
        onClick={onClick}
      >
        <span className="">{$t({ defaultMessage: "back to menu" })}</span>
        <SVGIcon className="w-2" name="arrowLeft" />
      </button>
    </div>
  );
}
