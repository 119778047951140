const hostMapping: Record<string, string> = {
  "realfast.ru": "dev",
  "templat.ru": "stage",
  localhost: "local",
};

export function getSentryEnv() {
  const hostname = window.location.hostname;
  let environment = "prod"; // Default to "prod"

  for (const domain in hostMapping) {
    const regex = new RegExp(`^(.*?)\\.${domain}$`);
    if (hostname.match(regex) || hostname === domain) {
      environment = hostMapping[domain];
      break;
    }
  }

  return environment;
}
