import { apiClient } from "@lobby/api-client";
import { useInfiniteQuery } from "@tanstack/react-query";

import { useAuth } from "@shared/lib";

class GameHistoryModel {
  useBetHistory() {
    const { isAuth } = useAuth();

    return useInfiniteQuery({
      enabled: isAuth,
      staleTime: 1000 * 15,
      queryKey: ["History.getSessions"],
      queryFn: ({ pageParam }) =>
        apiClient.send({
          endpoint: "History.getSessions",
          params: {
            limit: 30,
            offset: pageParam * 30,
          },
        }),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPage.result?.data.length === 0) {
          return undefined;
        }
        return lastPageParam + 1;
      },
      getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
        if (firstPageParam <= 1) {
          return undefined;
        }
        return firstPageParam - 1;
      },
    });
  }

  useSportBetHistory(status?: "outcome" | "cancelled" | "cashout" | "created" | "non-created") {
    const { isAuth } = useAuth();

    return useInfiniteQuery({
      staleTime: 1000 * 15,
      enabled: isAuth,
      queryKey: ["History.getSport", status],
      queryFn: async ({ pageParam }) => {
        const result = await apiClient.send({
          endpoint: "History.getSport",
          params: {
            limit: 10,
            offset: pageParam * 10,
            status,
          },
        });

        if (result.error) {
          return {
            ...result,
            result: {
              total: 0,
              data: [],
            },
          };
        }

        return result;
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPage.result?.data.length === 0) {
          return undefined;
        }
        return lastPageParam + 1;
      },
      getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
        if (firstPageParam <= 1) {
          return undefined;
        }
        return firstPageParam - 1;
      },
    });
  }
}

export const GameHistory = new GameHistoryModel();
