import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useRef, useState } from "react";

import { Player } from "@entities/player";
import { APIError, useErrorTranslate } from "@shared/lib";
import { Button, Input, SVGIcon } from "@shared/ui";

export function ChangePasswordForm() {
  const { $t } = useTranslate();

  const formRef = useRef<HTMLFormElement>(null);
  const oldPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);

  const { formatMessage } = useErrorTranslate();

  const [error, setError] = useState<APIError | null>();
  const [canUpdate, setCanUpdate] = useState(false);

  const updateMutation = Player.useChangePassword();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const oldPassword = oldPasswordRef.current?.value;
    const newPassword = newPasswordRef.current?.value;

    if (!oldPassword || !newPassword) {
      return;
    }

    updateMutation.mutate({
      oldPassword,
      newPassword,
    });
  }

  function handleChange() {
    const oldPassword = oldPasswordRef.current?.value;
    const newPassword = newPasswordRef.current?.value;

    setCanUpdate(Boolean(oldPassword && newPassword));
  }

  useEffect(() => {
    const errorData = updateMutation.data?.error;

    if (errorData) {
      const apiError = new APIError(errorData.message, { code: errorData.code });
      setError(apiError);
    } else {
      setError(null);
    }

    if (updateMutation.data?.result === "success") {
      formRef.current!.reset();
      setCanUpdate(false);
    }
  }, [updateMutation.data]);

  return (
    <form className="relative h-full" onSubmit={handleSubmit} onChange={handleChange} ref={formRef}>
      <Input
        ref={oldPasswordRef}
        label={$t({ defaultMessage: "Current Password" })}
        type="password"
        name="current-password"
        placeholder={$t({ defaultMessage: "Password" })}
        autoComplete="current-password"
        minLength={6}
        maxLength={255}
        required
      />
      <Input
        ref={newPasswordRef}
        className="mt-5"
        label={$t({ defaultMessage: "New Password" })}
        type="password"
        name="new-password"
        placeholder={$t({ defaultMessage: "New Password" })}
        autoComplete="new-password"
        minLength={6}
        maxLength={255}
        required
      />
      {error && (
        <div className="lg:text-xs text-2xs text-radical-red mt-2">{formatMessage(error)}</div>
      )}
      <div className="flex items-center gap-3 lg:absolute right-0 bottom-0 mobile-only:justify-end mobile-only:mt-6">
        {updateMutation.isPending && (
          <SVGIcon className={`${"animate-spin"} *:size-4`} name="reload" />
        )}
        <Button
          type="primary"
          htmlType="submit"
          disabled={!canUpdate}
          loading={updateMutation.isPending}
        >
          {$t({ defaultMessage: "Update" })}
        </Button>
      </div>
    </form>
  );
}
