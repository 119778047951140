import { useIntlT, useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useMemo } from "react";

import { GameHistory as History } from "@entities/game-history";
import { usePlayerMoneyFormatter } from "@entities/player";
import { range, useInfiniteScroll, useMobile } from "@shared/lib";
import { SVGIcon } from "@shared/ui";
import { Skeleton } from "@shared/ui/skeleton";
import { getMobileSectionScrollContainer } from "@widget/profile-modal/lib";

import { NoDataPlaceholder } from "../../no-data-placeholder";
import "./styles.css";

export function GameHistory({ className }: { className?: string }) {
  const isMobile = useMobile();
  const { data, fetchNextPage, isFetchingNextPage, isLoading } = History.useBetHistory();

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);
  const { $t } = useTranslate();
  const { intl } = useIntlT();
  const formatMoney = usePlayerMoneyFormatter();

  const gameHistory = useMemo(() => data?.pages.flatMap((page) => page.result?.data) ?? [], [data]);

  const isGameHistoryExist = gameHistory.length > 0;

  return (
    <div className={clsx(className, "lg:max-h-full flex flex-col min-h-0")}>
      <div
        className="lg:overflow-y-auto custom-scrollbar scrollbar-thin gutter-stable"
        ref={scrollRef}
      >
        {!isGameHistoryExist && !isFetchingNextPage && !isLoading && (
          <NoDataPlaceholder
            topText={$t({ defaultMessage: "The list of games will appear here soon" })}
            bottomText={$t({ defaultMessage: "Play your first game" })}
            icon={<SVGIcon name="gamepad" className="w-18" />}
          />
        )}

        {isLoading && range(10)((idx) => <Skeleton key={idx} className="bg-silver h-9 mb-2" />)}

        {isGameHistoryExist && (
          <div className="lg:ml-2">
            <table className="game-history-table lg:-ml-2">
              <thead>
                <tr>
                  <th>{$t({ defaultMessage: "Date" })}</th>
                  <th>{$t({ defaultMessage: "Game" })}</th>
                  <th>{$t({ defaultMessage: "Bet" })}</th>
                  <th>{$t({ defaultMessage: "Win" })}</th>
                  <th>{$t({ defaultMessage: "Revenue" })}</th>
                </tr>
              </thead>
              <tbody>
                {gameHistory.map(({ createdAt, gameName, betSum, winSum, profitSum }, idx) => (
                  <tr key={idx}>
                    <td>
                      {new Date(createdAt * 1e3).toLocaleString(intl.locale, {
                        timeStyle: "short",
                        dateStyle: "short",
                      })}
                    </td>
                    <td>{gameName}</td>
                    <td>{formatMoney(betSum, { style: "decimal" })}</td>
                    <td>{formatMoney(winSum, { style: "decimal" })}</td>
                    <td>{formatMoney(profitSum, { style: "decimal" })}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isFetchingNextPage && (
              <div className="flex items-center justify-center my-2 h-10">
                <SVGIcon className="*:size-5 text-keppel animate-spin" name="reload" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
