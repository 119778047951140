import { type IntlShape } from "react-intl";

export function getMobileSectionScrollContainer() {
  return document.getElementById("profile-section-mobile") as HTMLDivElement;
}

export function translateBonusStatus(context: IntlShape["formatMessage"], status: string) {
  return context({
    id: `bonus_status/${status}`,
    defaultMessage: status,
  });
}
