import { FortuneWheel } from "@entities/fortune-wheel";
import { FortuneWheelButton } from "@features/fortune-wheel-button";
import { GameSearch } from "@features/game-search";
import { TakeBonusButton } from "@features/take-bonus-button";
import { useMobile } from "@shared/lib";
import { FloatGroup } from "@shared/ui";
import { BannerPanel } from "@widget/banner-panel";
import { CategoryList } from "@widget/category-list";
import { Footer } from "@widget/footer";
import { GameLists } from "@widget/game-lists";
import { Header } from "@widget/header";
import { JackpotTickerSlots } from "@widget/jackpot-ticker-slots";
import { LogInModal } from "@widget/log-in-modal";
import { PasswordForgotModal } from "@widget/password-forgot-modal";
import { ProviderList, ProvidersModal } from "@widget/provider-list";
import { RegisterModal } from "@widget/register-modal";
import { SupportChat } from "@widget/support-chat";

export function HomePage() {
  const isMobile = useMobile();
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  return (
    <>
      <Header />
      <main className="mx-auto mobile-only:contents max-w-screen-3xl">
        <div className="mobile-only:contents px-5">
          <>
            <LogInModal />
            <RegisterModal />
            <PasswordForgotModal />
            <ProvidersModal />
          </>

          <div className="mt-3 lg:mt-5">
            <BannerPanel />
          </div>

          <div className="mt-3 lg:mt-5">
            <JackpotTickerSlots />
          </div>

          {isMobile ? (
            <>
              <div className="p-3">
                <div className="flex-grow rounded-xs bg-athens-gray p-3 dark:bg-outer-space">
                  <GameSearch />
                </div>

                {isFortuneWheelAvailable && (
                  <div className="mt-3">
                    <FortuneWheelButton.Mobile />
                  </div>
                )}

                <div className="mt-3">
                  <ProviderList />
                </div>
              </div>

              <CategoryList />
              <div className="mt-3" id="navigate-category-anchor">
                <GameLists />
              </div>
            </>
          ) : (
            <div
              className="mt-5 grid grid-cols-[18.5rem_minmax(0,auto)] gap-5"
              id="navigate-category-anchor">
              <CategoryList />
              <div className="grow">
                <ProviderList />
                <GameLists className="mt-5" />
              </div>
            </div>
          )}
        </div>
      </main>
      <Footer className="mt-3 lg:mt-5" />

      <FloatGroup>
        <SupportChat className="mobile-only:z-[1]" />
        <TakeBonusButton className="mt-5 lg:mt-10" />
      </FloatGroup>
    </>
  );
}
