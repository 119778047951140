import { useEffect, useRef } from "react";

export function useInfiniteScroll(
  onScrollEnd: VoidFunction,
  scrollElement?: HTMLDivElement | null,
) {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollListener = (ev: Event) => {
      ev.stopPropagation();
      const { scrollHeight, scrollTop, clientHeight } = ev.currentTarget as HTMLDivElement;
      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        onScrollEnd();
      }
    };

    if (scrollElement) {
      scrollRef.current = scrollElement;
    }

    scrollRef.current?.addEventListener("scroll", scrollListener);

    return () => scrollRef.current?.removeEventListener("scroll", scrollListener);
  }, [onScrollEnd, scrollElement]);

  return scrollRef;
}
