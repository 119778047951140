import { createFileRoute } from "@tanstack/react-router";

import {
  redirectIfAuthenticatedUserRequired,
  redirectIfFortuneWheelIsNotAvailable,
} from "@app/lib";
import { FortuneWheelPage } from "@pages/fortune-wheel";

export const Route = createFileRoute("/fortune-wheel")({
  beforeLoad: async () => {
    await redirectIfAuthenticatedUserRequired();
    await redirectIfFortuneWheelIsNotAvailable();
  },

  component: FortuneWheelPage,
  onError: console.error,
});
