import { createContext, useEffect, useState } from "react";

import { emitter } from "../../../shared/lib";

import type { PropsWithChildren } from "react";

const initialState = {
  theme: (localStorage.getItem("theme") as "dark" | "light") ?? "dark",
};

export const LobbySettingsContext = createContext(initialState);

export function LobbySettingsContextProvider({ children }: PropsWithChildren) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const subscribers = [
      emitter.on("THEME_CHANGED", (theme) => {
        setState((prev) => ({ ...prev, theme }));
        localStorage.setItem("theme", theme);
        document.documentElement.className = theme;
      }),
    ];

    return () => {
      for (const unsubscribe of subscribers) {
        unsubscribe();
      }
    };
  }, []);

  return <LobbySettingsContext.Provider value={state}>{children}</LobbySettingsContext.Provider>;
}
