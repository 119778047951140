import { useEffect, useMemo, useState } from "react";

export function useFortuneWheelCountdown(unixTimestamp = 0) {
  const [timeDifference, setTimeDifference] = useState(0);

  // Add extra 60 seconds to the timestamp to make sure the countdown is over on the server
  const nextWinTimestamp = useMemo(() => (unixTimestamp + 60) * 1e3, [unixTimestamp]);

  useEffect(() => {
    if (!nextWinTimestamp) return;

    function updateCountdown() {
      setTimeDifference(Math.max(nextWinTimestamp - Date.now(), 0));
    }

    const interval = setInterval(updateCountdown, 1000);

    updateCountdown();

    return () => clearInterval(interval);
  }, [nextWinTimestamp]);

  return timeDifference;
}
