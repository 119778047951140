import { apiClient } from "@lobby/api-client";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";

import { JackpotContext, JackpotMap, type TJackpotsType, useRpcWebsocket } from "@entities/jackpot";
import { emitter } from "@shared/lib";

import type { ISlot, ISlotView, IWin } from "@entities/jackpot";
import { useAuth } from "@lobby/core/shared";
import type { PropsWithChildren } from "react";

export const JackpotContextProvider = ({ children }: PropsWithChildren) => {
  const [slots, setSlots] = useState<ISlot[] | null>(null);
  const [wins, setWins] = useState<IWin[] | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const {
    addWinsListener,
    acceptWin,
    handleGetSlotsAndWins,
    connect,
    addSlotsListener,
    isOpen,
    init,
    error,
  } = useRpcWebsocket();

  const { isAuth } = useAuth();

  const JackpotsStreamQuery = useQuery({
    enabled: isAuth,
    queryKey: ["Lobby.getWsStream"],
    queryFn: () => apiClient.send({ endpoint: "Lobby.getWsStream" }),
  });

  const streamId = JackpotsStreamQuery?.data?.result ?? null;

  const fetchConnection = useCallback(async () => {
    if (!isOpen) {
      await connect();
    }
  }, [isOpen]);

  const fetchSlotsState = useCallback(async () => {
    if (!isOpen) {
      return;
    }

    const { slots, wins } = await handleGetSlotsAndWins();

    if (slots) {
      setSlots(slots);
    }

    if (wins) {
      setWins(wins);
    }
  }, [isOpen, streamId]);

  const fetchWinAccept = useCallback(
    async (winId: number) => {
      if (!isOpen) {
        return;
      }

      await acceptWin(winId);
      const { slots, wins } = await handleGetSlotsAndWins();

      setWins(wins);
      setSlots(slots);
    },

    [isOpen],
  );

  const addSlotsHandlers = useCallback(() => {
    if (!isOpen) {
      return;
    }

    const slotsListener = (updatedSlots: ISlot[] | null) => {
      if (updatedSlots) {
        setSlots((prevSlots) => {
          const updatedSlotIds = updatedSlots.map((updatedSlot) => updatedSlot.slot);
          const newSlots = prevSlots?.map((prevSlot) => {
            const index = updatedSlotIds.indexOf(prevSlot.slot);
            return index !== -1 ? updatedSlots[index] : prevSlot;
          });

          return newSlots ?? prevSlots;
        });
      }
    };

    addSlotsListener(slotsListener);
  }, [isOpen]);

  const addWinHandlers = useCallback(() => {
    if (!isOpen) {
      return;
    }

    const winListener = (win: IWin | null) => {
      if (win) {
        setWins((prevState) => [...(prevState ?? []), win]);
      }
    };

    addWinsListener(winListener);
  }, [isOpen]);

  const computedSlots: ISlotView[] | null = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    const sortedJp = slots?.sort((a, b) => a.slot - b.slot);

    const sortedSlots = sortedJp?.map((slot) => ({
      value: slot.value,
      name: JackpotMap[slot.slot] as TJackpotsType,
      slot: slot.slot,
    }));

    return sortedSlots ?? null;
  }, [isOpen, slots]);

  const computedWins: IWin[] | null = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    return wins;
  }, [isOpen, wins]);

  useEffect(() => {
    if (streamId) {
      init(streamId).then(() => {
        Promise.all([fetchConnection(), fetchSlotsState(), addSlotsHandlers(), addWinHandlers()]);
      });
    } else {
      JackpotsStreamQuery.refetch();
    }
  }, [
    isOpened,
    addSlotsHandlers,
    addWinHandlers,
    fetchConnection,
    fetchSlotsState,
    init,
    streamId,
  ]);

  useEffect(() => {
    return emitter.on("PLAYER_LOGIN", () => {
      setIsOpened(true);
    });
  }, []);

  const computedWinsPending = useMemo(() => {
    return computedWins?.filter((win) => win.state === "pending") ?? null;
  }, [computedWins]);

  return (
    <JackpotContext.Provider
      value={{
        slots: computedSlots,
        wins: computedWinsPending,
        fetchWinAccept,
        error,
      }}
    >
      {children}
    </JackpotContext.Provider>
  );
};
