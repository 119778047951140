import { createContext, useContext } from "react";

export type TJackpotsType = "mini" | "major" | "grand" | "ultimate";

export interface ISlot {
  isCommunity: boolean | null;
  jackpotId: string;
  value: number;
  slot: number;
  name: TJackpotsType;
}

export enum JackpotMap {
  "mini",
  "major",
  "grand",
  "ultimate",
}

export interface IWin {
  value: number;
  id: number;
  state: "pending" | "paid" | "canceled";
  isViewed?: boolean;
  isCommunity?: boolean;
  jackpotId: string;
  slot: number;
}

export interface IJackpotsContext {
  wins: IWin[] | null;
  slots: ISlotView[] | null;
  fetchWinAccept: (windId: number) => void;
  error: string | null;
}

export type ISlotView = Pick<ISlot, "name" | "value" | "slot">;

const initialAppContextValue: IJackpotsContext = {
  slots: null,
  wins: null,
  fetchWinAccept: () => null,
  error: null,
};

export const JackpotContext = createContext<IJackpotsContext>(
  initialAppContextValue,
);

export const useJackpotsContext = () => {
  const context = useContext(JackpotContext);
  if (!context) {
    throw new Error(
      "maybe the application is not wrapped in JackpotsProvider Component",
    );
  }

  return context;
};
