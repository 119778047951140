interface IWheelSectorProps {
  fill: string;
  path: string;
  isWinSector: boolean;
}

export function WheelSector({
  fill,
  path,
  isWinSector,
  ...restProps
}: IWheelSectorProps & React.SVGProps<SVGGElement>) {
  return (
    <g {...restProps}>
      <path fill={fill} d={path} />
      <path stroke="url(#linGrad003)" strokeWidth={3} d={path} />
      {isWinSector && (
        <path fill="#fff" fillOpacity="0" d={path}>
          <animate
            attributeName="fill-opacity"
            calcMode="discrete"
            values="0;0.25"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      )}
    </g>
  );
}
