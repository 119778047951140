export function BackgroundDecorators() {
  return (
    <div className="absolute flex-center translate-y-[-8rem] *:flex-center *:absolute">
      <div className="*:w-[43.375rem] *:[aspect-ratio:694/584] gap-24">
        <svg
          className="scale-x-[-1]"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 694 584"
        >
          <path
            fill="url(#paint0_linear_4686_1852)"
            fillOpacity=".3"
            d="M.2 549.5c21.7 1.3 43.6-1 65.2-7.5 7.2-2.2 28-17.3 28-17.3s42.1 6.8 67.7-.8c33.3-10 73.6-30.3 92.8-54.8.8-1-.2-2.4-1.4-2.2-14 3-27.9-.4-36-5.5l-.3-.2c-13.8-8.6-20.8-13-30.7-23.6l3.2-6.6c9.2-.8 20.2 2.1 32 5.3l8.2 2.1c22.7 5.7 54.5-4 68-20l5-5.9c3.4-4.2 6.9-8.3 10.5-12.3l5.7-6.4c9.7-11.3 21.4-25 35.7-25.4 1 0 1.5-1.6.6-2.4a39 39 0 0 0-30-10.6c-10.1 1-19 5-28.2 9l-5.7 2.5c-19.4 8.3-39.5.8-57.3-12.3-1-.8-.5-2.6.8-2.4 26.2 2.3 53.2-9.1 67.4-32a488 488 0 0 0 11.2-20c3.4-6.2 6.7-12.3 9-16.2 29.7-49.8 85.5-64 134.4-57.4 1.3.2 2-1.5 1-2.3a702.6 702.6 0 0 1-87-49.3c-21.3-14-26.7-17.6-58.3-17-18.8.5-33 6.2-45.8 11.5-24.4 9.9-44.6 18-84.4-14.2 16.8-5.5 30.3-21.8 39.1-39 .8-1.6-.2-3.4-1.8-3.1-13.8 2.8-24 4.2-39.6-4-7.6-4-14.9-8.5-22.2-13.1a202.7 202.7 0 0 0-35-19c-7-2.7-17.1-1.6-26.8-.7a89 89 0 0 1-17.7.7c-9.5-5-19.8-9.6-30.7-13.6a280 280 0 0 1 54-14.5c25.9-15 55.1-27.2 87.5-35.9 42.4-11.4 98.7-17.8 134.4-8 1.5.5 1.3 2.3-.1 2.8-17 5.9-30 17.3-35.9 26.7l-.1.3c-9.8 16-14.7 24-19 39.3l7.5 4c10.5-5 20.6-14.1 31.6-24.1l7.6-6.8c21.1-18.7 61.7-29.4 86.1-23.5l9 2.2c6.4 1.6 12.6 3.2 19 4.5 3.2.6 6.6 1.4 10 2.2h.1c17.5 4 38.6 9 54.3.8 1.2-.7 2.7.4 2.1 1.7-5 12-13.2 21.6-26.2 27.5a140.3 140.3 0 0 1-36 9l-7.8 1.3c-26.2 4.5-43.5 23.2-54.8 45.4-.7 1.4 1 2.6 2.4 1.7 27-17.9 63.4-24.2 92.7-12.6 6 2.3 15.3 6.6 24.4 10.8 7.5 3.4 14.8 6.8 19.7 8.8 62.7 25.8 132 4.7 181.3-30.7 1.2-.9 2.9.2 2.4 1.5-22 59.6-57.7 114.1-117.6 146a183.1 183.1 0 0 1-56.8 17.7c-32.6 6-59.5 11-83.1 63.4 21.5-5.3 46.3.8 66.4 10.7 1.8.9 1.7 3.1-.2 3.8C485 345.3 473 350.3 461 367c-5.8 8-11 16.5-16.1 25-8 13.1-16 26.2-26.5 37.8-6 6.5-17.7 11.8-28.7 16.7-7.6 3.4-15 6.7-19.8 10.2-33.4 46.8-92 98.3-166.4 118.3A277 277 0 0 1 .2 549.5Z"
          />
          <defs>
            <linearGradient id="paint0_linear_4686_1852" gradientTransform="rotate(-20)">
              <stop className="[stop-color:#e7e7e7] dark:[stop-color:#141414]" />
              <stop offset="1" stopColor="#13c7bc" />
            </linearGradient>
          </defs>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 694 584">
          <path
            fill="url(#paint0_linear_4686_1852)"
            fillOpacity=".3"
            d="M.2 549.5c21.7 1.3 43.6-1 65.2-7.5 7.2-2.2 28-17.3 28-17.3s42.1 6.8 67.7-.8c33.3-10 73.6-30.3 92.8-54.8.8-1-.2-2.4-1.4-2.2-14 3-27.9-.4-36-5.5l-.3-.2c-13.8-8.6-20.8-13-30.7-23.6l3.2-6.6c9.2-.8 20.2 2.1 32 5.3l8.2 2.1c22.7 5.7 54.5-4 68-20l5-5.9c3.4-4.2 6.9-8.3 10.5-12.3l5.7-6.4c9.7-11.3 21.4-25 35.7-25.4 1 0 1.5-1.6.6-2.4a39 39 0 0 0-30-10.6c-10.1 1-19 5-28.2 9l-5.7 2.5c-19.4 8.3-39.5.8-57.3-12.3-1-.8-.5-2.6.8-2.4 26.2 2.3 53.2-9.1 67.4-32a488 488 0 0 0 11.2-20c3.4-6.2 6.7-12.3 9-16.2 29.7-49.8 85.5-64 134.4-57.4 1.3.2 2-1.5 1-2.3a702.6 702.6 0 0 1-87-49.3c-21.3-14-26.7-17.6-58.3-17-18.8.5-33 6.2-45.8 11.5-24.4 9.9-44.6 18-84.4-14.2 16.8-5.5 30.3-21.8 39.1-39 .8-1.6-.2-3.4-1.8-3.1-13.8 2.8-24 4.2-39.6-4-7.6-4-14.9-8.5-22.2-13.1a202.7 202.7 0 0 0-35-19c-7-2.7-17.1-1.6-26.8-.7a89 89 0 0 1-17.7.7c-9.5-5-19.8-9.6-30.7-13.6a280 280 0 0 1 54-14.5c25.9-15 55.1-27.2 87.5-35.9 42.4-11.4 98.7-17.8 134.4-8 1.5.5 1.3 2.3-.1 2.8-17 5.9-30 17.3-35.9 26.7l-.1.3c-9.8 16-14.7 24-19 39.3l7.5 4c10.5-5 20.6-14.1 31.6-24.1l7.6-6.8c21.1-18.7 61.7-29.4 86.1-23.5l9 2.2c6.4 1.6 12.6 3.2 19 4.5 3.2.6 6.6 1.4 10 2.2h.1c17.5 4 38.6 9 54.3.8 1.2-.7 2.7.4 2.1 1.7-5 12-13.2 21.6-26.2 27.5a140.3 140.3 0 0 1-36 9l-7.8 1.3c-26.2 4.5-43.5 23.2-54.8 45.4-.7 1.4 1 2.6 2.4 1.7 27-17.9 63.4-24.2 92.7-12.6 6 2.3 15.3 6.6 24.4 10.8 7.5 3.4 14.8 6.8 19.7 8.8 62.7 25.8 132 4.7 181.3-30.7 1.2-.9 2.9.2 2.4 1.5-22 59.6-57.7 114.1-117.6 146a183.1 183.1 0 0 1-56.8 17.7c-32.6 6-59.5 11-83.1 63.4 21.5-5.3 46.3.8 66.4 10.7 1.8.9 1.7 3.1-.2 3.8C485 345.3 473 350.3 461 367c-5.8 8-11 16.5-16.1 25-8 13.1-16 26.2-26.5 37.8-6 6.5-17.7 11.8-28.7 16.7-7.6 3.4-15 6.7-19.8 10.2-33.4 46.8-92 98.3-166.4 118.3A277 277 0 0 1 .2 549.5Z"
          />
          <defs>
            <linearGradient id="paint0_linear_4686_1852" gradientTransform="rotate(-20)">
              <stop className="[stop-color:#e7e7e7] dark:[stop-color:#141414]" />
              <stop offset="1" stopColor="#13c7bc" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="*:w-[43.375rem] *:[aspect-ratio:787/547] gap-32 translate-y-2/3">
        <svg
          className="scale-x-[-1]"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 787 547"
        >
          <path
            fill="url(#paint0_linear_4686_2217)"
            fillOpacity=".3"
            fillRule="evenodd"
            d="M0 469.4c20.6 7 42.5 10.3 65 9.7 7.5-.3 31.5-9.4 31.5-9.4s39 17.4 65.5 16.6c34.8-1 79-10.2 104-28.8 1-.8.4-2.4-.9-2.5a51.4 51.4 0 0 1-33.4-14.7l-.1-.2c-11.1-12-16.8-18-23.6-30.8a241 241 0 0 0 4.8-5.6c9.1 1.7 19 7.3 29.6 13.4l7.3 4.2c20.4 11.4 53.7 10.3 70.9-1.6l6.3-4.5c4.4-3.1 8.8-6.3 13.4-9.2l7-4.7c12.4-8.4 27.3-18.5 41.1-15.3 1.1.3 2-1.1 1.3-2.1a39 39 0 0 0-26.4-18c-10-1.7-19.6-.2-29.5 1.3l-6 1c-21 3-38.5-9.5-52.2-26.7-.9-1.1.1-2.6 1.4-2.2 24.6 9 53.7 5 73.3-13.3a490 490 0 0 0 16-16.5c4.9-5.1 9.7-10.2 12.9-13.3 41.6-40.4 99.1-39.7 144.7-20.7 1.2.5 2.2-.9 1.5-2-40.2-35-59-56.3-71.2-70-17-19.2-21.2-24-52-31.5-18.2-4.5-33.3-2.6-47.2-.9-26 3.3-47.7 6-77.8-35.5 17.6-1 35-13.2 48-27.6 1-1.3.5-3.4-1.1-3.5-14-.8-24.3-2.1-37.2-14-6.3-5.8-12.2-12.1-18-18.5a202.8 202.8 0 0 0-29-27.4c-6-4.3-16-6-25.6-7.5a89 89 0 0 1-17.4-4 250.4 250.4 0 0 0-26-21 279.9 279.9 0 0 1 55.9 0C255.6 4.3 287 0 320.6 0c43.9 0 99.9 8.3 131.9 27.1 1.3.8.6 2.5-1 2.7-18 1.2-33.4 8.9-41.4 16.5l-.2.2a139.4 139.4 0 0 0-28.6 33l6.2 5.8c11.5-2 23.6-8.3 36.9-15l9-4.7c25.3-12.6 67.3-12.4 89.3-.4l8.1 4.5c5.7 3.2 11.4 6.3 17.2 9.2 2.9 1.5 6 3 9.1 4.8 15.9 8.4 35 18.6 52.3 14.8 1.4-.3 2.5 1.1 1.6 2.2a49.5 49.5 0 0 1-32.4 19.8c-12.5 2-24.7.7-37.2-.6l-7.8-.8c-26.5-2.5-48 11.1-64.7 29.7-1 1.1.3 2.7 1.9 2.2 30.7-10.3 67.5-6.9 92.8 11.8 5.1 3.8 13 10.4 20.7 16.8 6.4 5.3 12.6 10.4 16.8 13.6 54 41.2 126.3 38.7 183 17.3 1.5-.6 2.8.9 2 2-36.7 51.8-85.2 95.3-151.4 110.6-22.8 5.3-42 3.8-59.4 2.4-33-2.7-60.3-4.8-96.7 39.7 22.2.5 44.5 12.8 61.4 27.6 1.5 1.3.8 3.4-1.2 3.6-17.7 1.3-30.5 3-46.3 16a370.7 370.7 0 0 0-22 19.9c-11.1 10.6-22.2 21.2-35.4 29.7-7.4 4.7-20.1 6.8-32.1 8.7-8.2 1.3-16.1 2.6-21.7 4.7-44.4 36.6-114.4 71.2-191.4 71.2A277 277 0 0 1 0 469.4Z"
            clipRule="evenodd"
          />
          <defs>
            <linearGradient id="paint0_linear_4686_2217" gradientTransform="rotate(-20)">
              <stop className="[stop-color:#e7e7e7] dark:[stop-color:#141414]" />
              <stop offset="1" stopColor="#13c7bc" />
            </linearGradient>
          </defs>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 787 547">
          <path
            fill="url(#paint0_linear_4686_2217)"
            fillOpacity=".3"
            fillRule="evenodd"
            d="M0 469.4c20.6 7 42.5 10.3 65 9.7 7.5-.3 31.5-9.4 31.5-9.4s39 17.4 65.5 16.6c34.8-1 79-10.2 104-28.8 1-.8.4-2.4-.9-2.5a51.4 51.4 0 0 1-33.4-14.7l-.1-.2c-11.1-12-16.8-18-23.6-30.8a241 241 0 0 0 4.8-5.6c9.1 1.7 19 7.3 29.6 13.4l7.3 4.2c20.4 11.4 53.7 10.3 70.9-1.6l6.3-4.5c4.4-3.1 8.8-6.3 13.4-9.2l7-4.7c12.4-8.4 27.3-18.5 41.1-15.3 1.1.3 2-1.1 1.3-2.1a39 39 0 0 0-26.4-18c-10-1.7-19.6-.2-29.5 1.3l-6 1c-21 3-38.5-9.5-52.2-26.7-.9-1.1.1-2.6 1.4-2.2 24.6 9 53.7 5 73.3-13.3a490 490 0 0 0 16-16.5c4.9-5.1 9.7-10.2 12.9-13.3 41.6-40.4 99.1-39.7 144.7-20.7 1.2.5 2.2-.9 1.5-2-40.2-35-59-56.3-71.2-70-17-19.2-21.2-24-52-31.5-18.2-4.5-33.3-2.6-47.2-.9-26 3.3-47.7 6-77.8-35.5 17.6-1 35-13.2 48-27.6 1-1.3.5-3.4-1.1-3.5-14-.8-24.3-2.1-37.2-14-6.3-5.8-12.2-12.1-18-18.5a202.8 202.8 0 0 0-29-27.4c-6-4.3-16-6-25.6-7.5a89 89 0 0 1-17.4-4 250.4 250.4 0 0 0-26-21 279.9 279.9 0 0 1 55.9 0C255.6 4.3 287 0 320.6 0c43.9 0 99.9 8.3 131.9 27.1 1.3.8.6 2.5-1 2.7-18 1.2-33.4 8.9-41.4 16.5l-.2.2a139.4 139.4 0 0 0-28.6 33l6.2 5.8c11.5-2 23.6-8.3 36.9-15l9-4.7c25.3-12.6 67.3-12.4 89.3-.4l8.1 4.5c5.7 3.2 11.4 6.3 17.2 9.2 2.9 1.5 6 3 9.1 4.8 15.9 8.4 35 18.6 52.3 14.8 1.4-.3 2.5 1.1 1.6 2.2a49.5 49.5 0 0 1-32.4 19.8c-12.5 2-24.7.7-37.2-.6l-7.8-.8c-26.5-2.5-48 11.1-64.7 29.7-1 1.1.3 2.7 1.9 2.2 30.7-10.3 67.5-6.9 92.8 11.8 5.1 3.8 13 10.4 20.7 16.8 6.4 5.3 12.6 10.4 16.8 13.6 54 41.2 126.3 38.7 183 17.3 1.5-.6 2.8.9 2 2-36.7 51.8-85.2 95.3-151.4 110.6-22.8 5.3-42 3.8-59.4 2.4-33-2.7-60.3-4.8-96.7 39.7 22.2.5 44.5 12.8 61.4 27.6 1.5 1.3.8 3.4-1.2 3.6-17.7 1.3-30.5 3-46.3 16a370.7 370.7 0 0 0-22 19.9c-11.1 10.6-22.2 21.2-35.4 29.7-7.4 4.7-20.1 6.8-32.1 8.7-8.2 1.3-16.1 2.6-21.7 4.7-44.4 36.6-114.4 71.2-191.4 71.2A277 277 0 0 1 0 469.4Z"
            clipRule="evenodd"
          />
          <defs>
            <linearGradient id="paint0_linear_4686_2217" gradientTransform="rotate(-20)">
              <stop className="[stop-color:#e7e7e7] dark:[stop-color:#141414]" />
              <stop offset="1" stopColor="#13c7bc" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}
