import { Chat } from "@lobby/core/entities/chat";
import { clsx } from "clsx";

export function UnreadBadge({ className }: { className?: string }) {
  const conversations = Chat.useConversationList();
  const conversation = conversations.data?.result?.[0] ?? null;

  const isRead = conversation?.isRead ?? true;
  if (isRead) {
    return null;
  }

  return (
    <div
      className={clsx(
        "flex h-8 w-8 flex-center rounded-full border-2 border-transparent bg-radical-red p-4",
        className,
      )}
    >
      <p className="stroke-transparent text-center font-bold text-white">new</p>
    </div>
  );
}
