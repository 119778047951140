import { useNavigate } from "@tanstack/react-router";
import { clsx } from "clsx";

import { LogoutButton } from "@features/player/logout-button";
import { useMobile } from "@shared/lib";
import { Button, SVGIcon } from "@shared/ui";

import type { TIconName } from "@shared/ui";
import type { TProfileSection } from "@widget/profile-modal/lib";
import type { TProfileSectionItem } from "./profile-sections";

interface IProfileSectionControlsProps {
  currentSectionId: TProfileSection | undefined;
  items: TProfileSectionItem[];
}

export function ProfileSectionControls({ items, currentSectionId }: IProfileSectionControlsProps) {
  const navigate = useNavigate();
  const isMobile = useMobile();

  return (
    <div className="flex flex-col rounded-xs bg-athens-gray p-3 lg:rounded-rounded lg:p-5 dark:bg-outer-space">
      <ul className="lg:custom-scrollbar flex mobile-only:max-h-max flex-col gap-2.5 lg:h-[16.75rem] lg:overflow-y-scroll">
        {items.map(({ title, icon, id }, idx) => (
          <li key={idx} className="mr-1">
            <Button
              className={clsx(
                "h-13 w-full justify-start whitespace-normal px-3 text-left text-base lg:h-15 lg:px-5 lg:dark:active:bg-java",
                id === currentSectionId && "bg-keppel text-white dark:bg-java",
              )}
              type="primary-alternate"
              onClick={() =>
                navigate({
                  search: (prev) => ({ ...prev, profileSection: id }),
                })
              }>
              <div className="flex items-center">
                <SVGIcon
                  className="size-[1.1875rem] min-w-[1.1875rem] *:size-full"
                  name={icon as TIconName}
                />
                <span className="ml-2.5">{title}</span>
              </div>
            </Button>
          </li>
        ))}
      </ul>
      {!isMobile && <LogoutButton className="mt-5" />}
    </div>
  );
}
