type APIErrorDetails = Record<string, unknown>;

export class APIError extends Error {
  details: APIErrorDetails;
  date: Date;

  constructor(message: string, details?: APIErrorDetails, options?: ErrorOptions) {
    super(message, options);

    this.details = details ?? { code: -1 };
    this.date = new Date();
  }
}
