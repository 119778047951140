import { createContext, useMemo, useRef, useState } from "react";

import { useJackpotsContext } from "@entities/jackpot";

import type { IWin } from "@entities/jackpot";
import type { PropsWithChildren } from "react";

export type TReelStatus = "spin-stop" | "spin-start" | "spin-finished";

export interface IMiniGameContext {
  fetchWinAccept: VoidFunction;
  updateReelStatus: (status: TReelStatus) => void;
  win: IWin | null;
  error: string | null;
  reelStatus: TReelStatus;
}

const initialAppContextValue: IMiniGameContext = {
  fetchWinAccept: () => null,
  updateReelStatus: () => null,
  win: null,
  reelStatus: "spin-stop",
  error: null,
};

export const MiniGameContext = createContext<IMiniGameContext>(initialAppContextValue);

export const MiniGameContextProvider = ({ children }: PropsWithChildren) => {
  const [reelStatus, setReelStatus] = useState(initialAppContextValue.reelStatus);
  const { fetchWinAccept, wins, error } = useJackpotsContext();
  const prevWin = useRef<IWin | null>(null);

  const winJackpot = useMemo(() => {
    if (prevWin.current) {
      return prevWin.current;
    }

    if (!wins) {
      return null;
    }

    const pendingWins = wins?.filter((win) => win.state === "pending");

    const [firstPending] = pendingWins;

    return firstPending;
  }, [wins]);

  const handleWin = () => {
    if (winJackpot?.id) {
      fetchWinAccept(winJackpot.id);
    }
  };

  return (
    <MiniGameContext.Provider
      value={{
        reelStatus,
        updateReelStatus: setReelStatus,
        win: winJackpot,
        fetchWinAccept: handleWin,
        error,
      }}
    >
      {children}
    </MiniGameContext.Provider>
  );
};
