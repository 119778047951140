import { useTranslate } from "@lobby/ocb-intl";

import Tabs from "@shared/ui/tabs";

import { BetHistoryList } from "./bet-history-list";

import "./styles.css";

export function BetHistory() {
  const { $t } = useTranslate();
  return (
    <div className="bet-history flex flex-col min-h-0 h-full">
      <Tabs
        items={[
          {
            label: $t({ defaultMessage: "In game" }),
            children: <BetHistoryList status="created" />,
          },
          {
            label: $t({ defaultMessage: "Completed" }),
            children: <BetHistoryList status="non-created" />,
          },
        ]}
      />
    </div>
  );
}
