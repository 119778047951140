import type { PropsWithChildren } from "react";

interface IFloatGroupProps {
  className?: string;
}

export function FloatGroup({ className, children }: PropsWithChildren<IFloatGroupProps>) {
  return (
    <div
      className={`lg:right-10 lg:bottom-10 fixed bottom-5 flex flex-col items-end z-[999] mobile-only:px-3 mobile-only:w-full ${className}`}
    >
      {children}
    </div>
  );
}
