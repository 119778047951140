import { clsx } from "clsx";

interface INoDataPlaceholderProps {
  className?: string;
  topText?: string;
  bottomText?: string;
  icon: React.ReactNode;
}

export function NoDataPlaceholder({
  className,
  topText,
  bottomText,
  icon,
}: INoDataPlaceholderProps) {
  return (
    <div className={clsx(className, "flex-center min-h-36 h-64 max-h-full")}>
      <div className="lg:text-xl text-base text-keppel font-bold text-center dark:text-java">
        {topText && <div>{topText}</div>}
        <div className="lg:my-5 inline-block my-3">{icon}</div>
        {bottomText && <div>{bottomText}</div>}
      </div>
    </div>
  );
}
