import { clsx } from "clsx";

interface IGameEventCardProps {
  className?: string;
  data:
    | {
        label: string;
        score: string;
        time: string;
        league: string;
      }
    | {
        label: string;
        date: string;
        time: string;
        league: string;
      };
}

export function GameEventCard({ className, data }: IGameEventCardProps) {
  return (
    <div
      className={clsx(
        className,
        "bg-white dark:bg-bright-gray rounded-rounded flex flex-col justify-center h-[5.375rem]",
      )}
    >
      <div className="grid grid-cols-[5rem,auto] gap-y-1 pr-5 whitespace-nowrap">
        <div className="contents font-bold">
          {"score" in data ? (
            <span className="justify-self-center text-keppel dark:text-java">{data.score}</span>
          ) : (
            <span className="justify-self-center">{data.date}</span>
          )}
          <span className="truncate">{data.label}</span>
        </div>
        <span className="justify-self-center">{data.time}</span>
        <span className="truncate">{data.league}</span>
      </div>
    </div>
  );
}
