import { useEffect, useState, type PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";

type TProps = {
	locale: string;
	version: string;
	basePath?: string;
};

export function ReactIntlProvider({ children, locale, basePath = "/", version }: PropsWithChildren<TProps>) {
	const [messages, setMessages] = useState({});

	const isStage = window.location.hostname.includes(".stage.");
	const url = `${isStage ? "/" : basePath}i18n/${locale}.json?v=${version}`;

	useEffect(() => {
		fetch(url, {
			headers: { "Content-Type": "Application/json" },
		})
			.then((response) => response.json())
			.then((messages) => {
				setMessages(messages);
			});
	}, [locale]);

	return (
		<IntlProvider locale={locale} messages={messages} onWarn={(warn) => console.warn(warn)}>
			{children}
		</IntlProvider>
	);
}
