import { Description, Field, Input, Label } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { useState } from "react";

import { Player } from "@entities/player";
import { APIError, useErrorTranslate, useMessage } from "@shared/lib";
import { Button } from "@shared/ui";

import type { FormEvent } from "react";

export function PasswordForgotForm() {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");

  const message = useMessage();
  const { $t } = useTranslate();
  const { formatMessage: translateError } = useErrorTranslate();

  const { mutate: resetPasswordMutate, isPending } = Player.useResetPassword();

  function handleSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    setValidationError("");

    const form = new FormData(ev.currentTarget);

    if (validateForm(form)) {
      resetPassword(form);
    }
  }

  function resetPassword(form: FormData) {
    resetPasswordMutate(
      {
        email: form.get("email") as string,
      },
      {
        onSuccess: (data) => {
          const error = data.error;
          if (error) {
            const message = translateError(new APIError(error.message, { code: error.code }));
            setValidationError(message);
          }

          if (data.result) {
            showSuccessMessage();
            setEmail("");
          }
        },
      },
    );
  }

  function validateForm(form: FormData) {
    return form.has("email");
  }

  function showSuccessMessage() {
    message.success(
      $t({ defaultMessage: "We've sent a link to reset your password. Check your inbox." }),
      { duration: 3000 },
    );
  }

  return (
    <div className="relative text-sm lg:text-base">
      <form onSubmit={handleSubmit}>
        <Field className="flex flex-col">
          <Description>
            {$t({
              defaultMessage:
                "Don’t worry. It happens. Just type your email address, and we will send you the instructions on how to reset your password and continue playing.",
            })}
          </Description>
          <Label className="mt-3 font-bold">{$t({ defaultMessage: "E-mail" })}:</Label>
          <Input
            className="input border-1 border-shark/80"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            autoCapitalize="none"
            minLength={6}
            maxLength={255}
            autoFocus
            required
          />
        </Field>

        <Button className="mt-5 w-full" type="primary" htmlType="submit" loading={isPending}>
          {$t({ defaultMessage: "Send" })}
        </Button>

        {validationError && (
          <div className="mt-3 text-center font-medium text-radical-red">{validationError}</div>
        )}
      </form>
    </div>
  );
}
