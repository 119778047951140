import { createFileRoute } from "@tanstack/react-router";

import { isLobbyDemoMode, redirectIfAuthenticatedUserRequired } from "@app/lib";
import { loadGameCategoryList } from "@app/lib/loaders";
import { GamePage } from "@pages/game";

export const Route = createFileRoute("/game/$gameId")({
  beforeLoad: async () => {
    !isLobbyDemoMode() && (await redirectIfAuthenticatedUserRequired());

    await loadGameCategoryList();
  },
  component: GamePage,
  onError: console.error,
});
