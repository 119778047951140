export const ProfileSection = {
  Bonuses: "bonuses",
  GameHistory: "gameHistory",
  SportBetsHistory: "sportBetsHistory",
  Wallet: "wallet",
  Info: "info",
  Settings: "settings",
} as const;

export const profileSections = Object.values(ProfileSection);

export type TProfileSection = (typeof ProfileSection)[keyof typeof ProfileSection];
