import { apiClient } from "@lobby/api-client";
import { onlineManager, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { APIError, CLIENT_ERRORS, clientErrorCodes, emitter, useAuth } from "../../../shared/lib";

import type { SendOptions } from "@lobby/api-client";

export const Player = {
  useLogIn() {
    const queryClient = useQueryClient();

    return useMutation({
      mutationKey: ["Player.logIn"],
      mutationFn: (params: SendOptions<"Player.logIn">["params"]) =>
        apiClient.send<"Player.logIn">({
          endpoint: "Player.logIn",
          params,
        }),
      onSuccess: (data) => {
        if (data.result) {
          emitter.emit("PLAYER_LOGIN");
          queryClient.clear();
          queryClient.setQueryData(["Player.getCurrent"], data);
          queryClient.removeQueries({ queryKey: ["Slider.getCurrent"] });
        }
      },
    });
  },

  useLogOut() {
    const queryClient = useQueryClient();

    return useMutation({
      mutationKey: ["Player.logOut"],
      mutationFn: () =>
        apiClient.send<"Player.logOut">({
          endpoint: "Player.logOut",
        }),
      onSuccess: () => {
        queryClient.clear();
        emitter.emit("PLAYER_LOGOUT");
      },
    });
  },

  usePlayer() {
    return useQuery({
      refetchInterval: 1000 * 15,
      queryKey: ["Player.getCurrent"],
      queryFn: () =>
        apiClient.send({
          endpoint: "Player.getCurrent",
          params: { isActive: "true" },
        }),
      select: (data) => data.result,
    });
  },

  useUpdatePlayer() {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (params: SendOptions<"Player.update">["params"]) =>
        apiClient.send<"Player.update">({
          endpoint: "Player.update",
          params,
        }),
      onSuccess: (data) => {
        if (data.error) {
          console.error(data.error.message);
        }

        if (data.result === "success") {
          queryClient.invalidateQueries({ queryKey: ["Player.getCurrent"] });
        }
      },
    });
  },

  useBonusHistory() {
    const { isAuth } = useAuth();

    return useQuery({
      staleTime: 1000 * 15,
      enabled: isAuth,
      queryKey: ["Bonus.getHistory"],
      queryFn: async () => {
        const result = await apiClient.send({
          endpoint: "Bonus.getHistory",
        });

        if (result.error) {
          console.error(result.error.message);
        }

        return result;
      },
      select: (data) => data?.result?.data,
    });
  },

  useBonusDescription() {
    const { isAuth } = useAuth();

    return useQuery({
      staleTime: 1000 * 15,
      enabled: isAuth,
      queryKey: ["Bonus.getDescription"],
      queryFn: async () => {
        const result = await apiClient.send({
          endpoint: "Bonus.getDescription",
        });

        if (result.error) {
          console.error(result.error.message);
        }

        return result;
      },
    });
  },

  useActivateBonus() {
    const queryClient = useQueryClient();
    const { data: player } = this.usePlayer();

    return useMutation({
      networkMode: "always",
      mutationKey: ["Bonus.activate"],
      mutationFn: () => {
        if (!onlineManager.isOnline()) {
          emitter.emit("ERROR_MODAL_OPEN", new Error(CLIENT_ERRORS[0]));
        }

        return apiClient.send<"Bonus.activate">({
          endpoint: "Bonus.activate",
        });
      },
      onError: (error) => emitter.emit("ERROR_MODAL_OPEN", new Error(error.message)),
      onSuccess: (data) => {
        if (data.error) {
          emitter.emit(
            "ERROR_MODAL_OPEN",
            new APIError(data.error.message, {
              details: {
                code: data.error.code,
                ...(player?.id && { PID: `${player.id} / ${player.hallId}` }),
              },
            }),
          );
        }

        queryClient.invalidateQueries({ queryKey: ["Player.getCurrent"] });
        queryClient.invalidateQueries({ queryKey: ["Player.getBalancesEx"] });
        queryClient.invalidateQueries({ queryKey: ["Bonus.getHistory"] });
      },
    });
  },

  useBalances() {
    const { isAuth } = useAuth();

    return useQuery({
      refetchInterval: 1000 * 15,
      staleTime: 1000 * 15,
      enabled: isAuth,
      queryKey: ["Player.getBalancesEx"],
      queryFn: async () => {
        const result = await apiClient.send({
          endpoint: "Player.getBalancesEx",
        });

        if (result.error) {
          console.error(result.error.message);
        }

        return result;
      },
      select: (data) => data?.result,
    });
  },

  useChangePassword() {
    return useMutation({
      mutationFn: (params: SendOptions<"Player.changePassword">["params"]) =>
        apiClient.send<"Player.changePassword">({
          endpoint: "Player.changePassword",
          params,
        }),
      onSuccess: (data) => {
        if (data.error) {
          console.error(data.error.message);
        }
      },
    });
  },
};
