import { useTranslate } from "@lobby/ocb-intl";

import { APIError } from "../api-error";

export function useErrorTranslate() {
  const { $t: tt } = useTranslate();

  function formatMessage(error: Error | string) {
    if (error instanceof APIError) {
      return tt({
        id: `api_errors/code_${error.details.code}`,
        defaultMessage: error.message,
      });
    }

    if (error instanceof Error) {
      return tt({ id: error.message });
    }

    return String(error);
  }

  return { formatMessage };
}
