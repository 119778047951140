import { useIntl } from "react-intl";

export function useTranslate() {
	const intl = useIntl();
	const $t = intl.formatMessage;
	return { $t };
}

export function useIntlT() {
	const intl = useIntl();
	return { intl };
}

export function useLocale() {
	const intl = useIntl();
	return intl.locale;
}
