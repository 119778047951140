export function Spin({ className }: { className?: string }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 69 43">
        <path
          className="fill-keppel dark:fill-java"
          fillRule="evenodd"
          d="M46.519 42.069c11.991 0 21.713-9.722 21.713-21.713C68.232 8.364 57.834 0 47.197 0c-6.046 0-11.535 2.714-15.02 5.59-.442.16-1.06.26-1.706.364-.94.152-1.936.313-2.517.686l-.002-.002c-1.035.665-1.905 1.498-2.778 2.333-.56.536-1.122 1.073-1.727 1.567-1.243 1.013-2.25 1.15-3.635 1.25-.16.013-.21.183-.092.286 1.326 1.158 3.075 2.127 4.818 2.161-2.855 3.498-5.002 3.327-7.59 3.121-1.374-.11-2.872-.229-4.666.186-5.192 1.199-9 4.612-11.883 8.676-.066.094.04.207.154.165l.115-.043c.453-.368.972-.739 1.536-1.091 1.284-.803 2.893-1.565 4.653-1.974 1.764-.41 3.728-.474 5.664.186 1.953.665 3.768 2.029 5.272 4.285l-1.574 1.049.017.014c.602.5 1.227 1.018 1.63 1.316 1.987 1.468 4.873 1.732 7.285.926.12-.04.227.083.147.175-1.31 1.455-3.002 2.521-5.08 2.327a56.01 56.01 0 0 1-.608-.06c-.98-.102-1.938-.202-2.92-.044-1.108.179-1.925.738-2.55 1.553-.064.083.021.196.13.172 1.36-.301 2.859.498 4.1 1.16h.001c.25.134.49.262.717.374.458.227.903.476 1.348.724.212.12.424.238.638.354 1.73.943 5.026.956 7.008-.034.242-.121.48-.243.713-.363 1.039-.534 1.99-1.022 2.89-1.178.162.152.326.301.492.448-.642 1.074-1.181 1.587-2.244 2.596l-.016.016c-.63.597-1.847 1.199-3.26 1.297-.121.008-.17.147-.069.207 2.51 1.476 6.908 2.129 10.353 2.129 2.634 0 5.099-.337 7.363-.947.728.074 1.467.112 2.215.112Zm.678-40.712c-10.872 0-19.677 8.805-19.677 19.677 0 2.388.433 4.68 1.204 6.794v.257l.21.239c2.882 7.257 9.97 12.388 18.263 12.388 10.873 0 19.677-8.805 19.677-19.678 0-10.872-8.804-19.677-19.677-19.677Zm-16 13.29a17.178 17.178 0 0 0-1.203 5.26l5.444 1.882 3.633 9.37-3.254 2.79c3.046 2.684 7.017 4.31 11.38 4.31 4.51 0 8.617-1.737 11.691-4.577l-8.58 3.663-5.457-5.682 6.74-7.582 9.21 1.137-1.503 8.072a17.23 17.23 0 0 0 5.111-11.164l-1.662-.415-3.108-8.759.705-3.05a17.265 17.265 0 0 0-6.605-4.809l-4.403-.56-1.964-.723h-.175a17.171 17.171 0 0 0-10.45 3.542l-1.11 1.788-4.44 5.507Zm23.865-3.91-6.97-6.5-9.542 3.429.328 9.255L49.355 18.9l5.707-8.163Z"
          clipRule="evenodd"
        />
        <g>
          <path
            fill="#2E3641"
            d="M66.768 21c0 10.866-8.809 19.675-19.675 19.675-10.866 0-19.675-8.809-19.675-19.675 0-10.866 8.809-19.675 19.675-19.675 10.866 0 19.675 8.809 19.675 19.675Z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M66.874 21.034c0-10.872-8.805-19.677-19.677-19.677-10.873 0-19.678 8.805-19.678 19.677a19.638 19.638 0 0 0 1.205 6.794v.257l.174.198c2.65 6.685 8.858 11.572 16.287 12.327-7.414-.753-13.606-5.623-16.252-12.286l-.035-.04a19.81 19.81 0 0 1-1.378-7.25c0-10.867 8.81-19.677 19.677-19.677 10.867 0 19.677 8.81 19.677 19.677ZM47.197 40.712c10.782 0 19.53-8.66 19.675-19.407-.144 10.742-8.898 19.407-19.675 19.407ZM29.994 19.907a17.182 17.182 0 0 1 1.202-5.26l4.44-5.507 1.11-1.788a17.171 17.171 0 0 1 10.45-3.542h.175l1.964.723 4.404.56a17.265 17.265 0 0 1 6.605 4.809l-.706 3.05 3.109 8.759 1.662.415a17.231 17.231 0 0 1-5.111 11.164l1.502-8.072-9.21-1.137-6.74 7.582 5.457 5.682 8.58-3.663a17.174 17.174 0 0 1-11.69 4.576c-4.364 0-8.335-1.625-11.38-4.309l3.253-2.79-3.632-9.37-5.444-1.882ZM48.09 4.237l6.97 6.499-5.706 8.163-10.477-1.978-.329-9.255 9.542-3.43Z"
            clipRule="evenodd"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="discrete"
            values="
                0 47.09 21;
                30 47.09 21;
                60 47.09 21;
                90 47.09 21;
                120 47.09 21;
                150 47.09 21;
                180 47.09 21;
                210 47.09 21;
                240 47.09 21;
                270 47.09 21;
                300 47.09 21;
                330 47.09 21
                "
            dur="1s"
            repeatCount="indefinite"
          />
        </g>
      </svg>
    </div>
  );
}
