import { BannerPanelDesktop } from "./banner-panel.desktop";
import { BannerPanelMobile } from "./banner-panel.mobile";

export function BannerPanel() {
  return (
    <>
      <div className="lg:hidden">
        <BannerPanelMobile />
      </div>
      <div className="mobile-only:hidden">
        <BannerPanelDesktop />
      </div>
    </>
  );
}
