import { createFileRoute, redirect } from "@tanstack/react-router";

import { isLobbyDemoMode, redirectIfUserIsAuthenticated } from "@app/lib/helpers";
import { loadAuthEndpoints, loadLobbyConfig } from "@app/lib/loaders";
import { SignInPage } from "@pages/sign-in";

export const Route = createFileRoute("/sign-in")({
  component: SignInPage,
  onError: console.error,
  beforeLoad: async () => {
    await redirectIfUserIsAuthenticated("/");

    await Promise.all([loadLobbyConfig(), loadAuthEndpoints()]);

    if (isLobbyDemoMode()) {
      throw redirect({
        to: "/casino",
        replace: true,
        search: {
          modal: "log-in",
        },
      });
    }
  },
  loader: ({ context }) => {
    context.queryClient.removeQueries({
      predicate: (query) => {
        return (
          query.queryKey[0] !== "SocialNetwork.getAuthEndpoints" &&
          query.queryKey[0] !== "Player.getCurrent" &&
          query.queryKey[0] !== "Lobby.getCurrent"
        );
      },
    });
  },
});
