import { useLobbyFormatters } from "@lobby/utils-formatters";

import { Player } from "@entities/player";

export const usePlayerMoneyFormatter = () => {
  const { data: player } = Player.usePlayer();
  const { formatMoney } = useLobbyFormatters();

  const currency = player?.currency || undefined;

  return (value: unknown, options: Intl.NumberFormatOptions = {}) =>
    formatMoney(value, { currency, ...options });
};
