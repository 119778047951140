import { clsx } from "clsx";
import { useEffect, useState } from "react";

interface IArrowProps {
  className?: string;
  disabled?: boolean;
  onClick: VoidFunction;
}

function Arrow({ className, disabled, onClick }: IArrowProps) {
  return (
    <button
      className={clsx(
        className,
        "bg-mercury rounded-rounded flex items-center justify-center w-7.5 h-8 hover:cursor-pointer active:bg-keppel disabled:pointer-events-none disabled:cursor-not-allowed group dark:bg-ebony-clay dark:active:bg-java dark:disabled:bg-ebony-clay",
      )}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" fill="none" viewBox="0 0 6 10">
        <path
          className="fill-keppel group-hover:fill-bright-gray group-disabled:fill-blue-bayoux dark:fill-java dark:group-hover:fill-white"
          fillRule="evenodd"
          d="M.968 6.414a2 2 0 0 1 0-3.13L4.378.57A1 1 0 0 1 6 1.354v6.99a1 1 0 0 1-1.623.782L.967 6.414Z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
}

interface IPaginationProps {
  className?: string;
  componentRef: HTMLDivElement | null;
}

export function Pagination({ className, componentRef }: IPaginationProps) {
  const [scrollPosition, setScrollPosition] = useState<"start" | "end" | "progress">("start");

  function onClickPrev() {
    componentRef?.scrollBy({
      left: -componentRef.children[0].clientWidth,
      behavior: "smooth",
    });
  }

  function onClickNext() {
    componentRef?.scrollBy({
      left: componentRef.children[0].clientWidth,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    function scrollListener(ev: Event) {
      const { scrollWidth, scrollLeft, clientWidth } = ev.currentTarget as HTMLDivElement;

      if (scrollLeft === 0) {
        setScrollPosition("start");
      } else if (Math.abs(scrollWidth - scrollLeft - clientWidth) < 1) {
        setScrollPosition("end");
      } else {
        setScrollPosition("progress");
      }
    }

    componentRef?.addEventListener("scroll", scrollListener);
    return () => componentRef?.removeEventListener("scroll", scrollListener);
  }, [componentRef]);

  return (
    <div className={clsx(className, "flex items-center justify-center gap-2")}>
      <Arrow onClick={onClickPrev} disabled={scrollPosition === "start"} />
      <Arrow className="rotate-180" onClick={onClickNext} disabled={scrollPosition === "end"} />
    </div>
  );
}
