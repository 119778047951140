import { ReactIntlProvider } from "@lobby/ocb-intl";
import { useEffect, useState } from "react";

import { emitter } from "@shared/lib";

import type { TLocale } from "@shared/lib/locales";
import type { PropsWithChildren } from "react";

export function LocaleProvider({ children }: PropsWithChildren) {
  const langStorage = (localStorage.getItem("locale") ?? "en") as TLocale;
  const [locale, setLocale] = useState<TLocale>(langStorage);

  useEffect(() => {
    document.documentElement.lang = locale;

    const unbind = emitter.on("SET_LOCALE", (locale) => {
      document.documentElement.lang = locale;
      setLocale(locale);
    });

    return unbind;
  }, []);

  return (
    <ReactIntlProvider
      locale={locale}
      basePath={import.meta.env.VITE_PUBLIC_PATH}
      version={import.meta.env.VITE_APP_VERSION}
    >
      {children}
    </ReactIntlProvider>
  );
}
