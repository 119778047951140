import { useNavigate } from "@tanstack/react-router";

import { Button, SVGIcon } from "@shared/ui";

export function ResetProviderFilterButton() {
  const navigate = useNavigate();
  return (
    <Button
      className="lg:px-2 px-1.5"
      onClick={() => {
        navigate({
          replace: true,
          search: (prev) => ({
            ...prev,
            pid: undefined,
          }),
        });
      }}
    >
      <SVGIcon className="lg:size-4 size-3" name="closeAlt" />
    </Button>
  );
}
