import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useRef, useState } from "react";

import { Button } from "@shared/ui/button";

import type { TBanner } from "../lib/types";

interface IBannersListProps {
  banners: TBanner[];
}

export function BannersListMobile({ banners }: IBannersListProps) {
  const [activeBanners, setActiveBanners] = useState(banners);
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const { $t } = useTranslate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentSlideIdx((prev) => (prev + 1) % activeBanners.length);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [currentSlideIdx, activeBanners]);

  useEffect(() => {
    let touchstartX = 0;
    let touchendX = 0;

    function checkDirection() {
      if (touchendX + 20 < touchstartX) {
        setCurrentSlideIdx((prev) => (prev + 1) % activeBanners.length);
      }

      if (touchendX - 20 > touchstartX) {
        setCurrentSlideIdx((prev) => (prev - 1 + activeBanners.length) % activeBanners.length);
      }
    }

    const touchstartHandler = (e: TouchEvent) => {
      touchstartX = e.changedTouches[0].screenX;
    };

    const touchendHandler = (e: TouchEvent) => {
      touchendX = e.changedTouches[0].screenX;
      checkDirection();
    };

    containerRef.current?.addEventListener("touchstart", touchstartHandler);
    containerRef.current?.addEventListener("touchend", touchendHandler);

    return () => {
      containerRef.current?.removeEventListener("touchstart", touchstartHandler);
      containerRef.current?.removeEventListener("touchend", touchendHandler);
    };
  }, []);

  useEffect(() => {
    setActiveBanners(banners);
  }, [banners]);

  return (
    <div className="h-full relative">
      <div className="flex absolute size-full" ref={containerRef}>
        {activeBanners.map(({ image, title, subtitle, button }, idx) => (
          <div
            className={`absolute p-3 size-full min-w-full transition-opacity duration-500 ease-linear ${idx === currentSlideIdx ? "opacity-100" : "opacity-0 pointer-events-none"}`}
            key={idx}
          >
            <img
              className="absolute top-0 left-0 h-full w-full object-contain"
              src={image}
              alt="promo-img"
              role="img"
            />
            <div className="relative h-full">
              {(title || subtitle) && (
                <div className="bg-ebony-clay/80 rounded-xs p-3 lg:p-5 text-white absolute overflow-hidden max-h-[85%] max-w-full top-0 before:[box-shadow:inset_0_0_0.625em_0.625em_rgba(0,0,0)] before:size-full before:absolute before:top-0 before:left-0">
                  {title.split("\n").map((substr, idx) => (
                    <div key={idx} className="text-xl font-bold">
                      {substr}
                    </div>
                  ))}
                  {subtitle.split("\n").map((substr, idx) => (
                    <div key={idx} className="text-lg">
                      {substr}
                    </div>
                  ))}
                </div>
              )}
              {button && (
                <div className="absolute bottom-0">
                  <Button type="primary" onClick={button.action}>
                    {button.textKey === "playNow"
                      ? $t({ defaultMessage: "play now" })
                      : $t({ defaultMessage: "open" })}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <ul className="flex gap-2 absolute bottom-5 right-5 [line-height:0]">
        {activeBanners.length > 1 &&
          activeBanners.map((_, idx) => (
            <li key={idx}>
              <button
                className={`border-2 border-keppel dark:border-java rounded-full size-2.5 ${idx === currentSlideIdx ? "bg-keppel dark:bg-java" : "bg-[#252D3ACC]"}`}
                type="button"
                onClick={() => setCurrentSlideIdx(idx)}
              />
            </li>
          ))}
      </ul>
    </div>
  );
}
