import { apiClient } from "@lobby/api-client";

import { queryClient } from "@lobby/core/app";

import { ensureUserIsAuthenticated } from "./helpers";

import type { ApiClientResponse } from "@lobby/api-client";
import type { QueryClient } from "@tanstack/react-query";

export async function loadFortuneWheelSettings(queryClient: QueryClient) {
  const isAuthenticated = await ensureUserIsAuthenticated();
  if (isAuthenticated) {
    return queryClient.ensureQueryData<ApiClientResponse<"FortuneWheel.getSettings">>({
      queryKey: ["FortuneWheel.getSettings"],
      queryFn: () =>
        apiClient.send({
          endpoint: "FortuneWheel.getSettings",
        }),
    });
  }
}

export async function loadLobbyConfig() {
  return await queryClient.ensureQueryData<ApiClientResponse<"Lobby.getCurrent">>({
    queryKey: ["Lobby.getCurrent"],
    queryFn: () =>
      apiClient.send({
        endpoint: "Lobby.getCurrent",
      }),
  });
}

export async function loadAuthEndpoints() {
  return await queryClient.ensureQueryData<ApiClientResponse<"SocialNetwork.getAuthEndpoints">>({
    queryKey: ["SocialNetwork.getAuthEndpoints"],
    queryFn: () =>
      apiClient.send({
        endpoint: "SocialNetwork.getAuthEndpoints",
      }),
  });
}

export async function loadGameCategoryList() {
  const lang = localStorage.getItem("locale") ?? "en";
  return await queryClient.ensureQueryData<ApiClientResponse<"Game.getCategoriesList">>({
    queryKey: ["Game.getCategoriesList", lang],
    queryFn: () =>
      apiClient.send({
        endpoint: "Game.getCategoriesList",
        params: { lang },
      }),
  });
}
