import { Link } from "@tanstack/react-router";

interface ISocialNetworkLinkProps {
  name: string;
  stringId: string;
  url: string;
}

export function SocialNetworkLink({ name, stringId, url }: ISocialNetworkLinkProps) {
  return (
    <Link
      to={url}
      key={stringId}
      className="rounded-rounded border-1 border-big-stone flex-center relative"
    >
      <span className="absolute max-w-full truncate invisible has-[+.hidden]:visible">{name}</span>
      <img
        src={`/media/social-network/${stringId}.jpg`}
        alt={name}
        onError={(e) => (e.target as HTMLImageElement).classList.add("hidden")}
        className="rounded-inherit object-cover size-full"
      />
    </Link>
  );
}
