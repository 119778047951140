import type React from "react";

import type { TLocale } from "@shared/lib/locales";

export const flagIcons: Record<TLocale, React.ReactNode> = {
  en: (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
      <rect width="100%" height="100%" fill="#fff" rx="2" />
      <path
        fill="#D02F44"
        d="M28 0H0v1.33h28V0Zm0 2.67H0V4h28V2.67ZM0 5.33h28v1.34H0V5.33ZM28 8H0v1.33h28V8ZM0 10.67h28V12H0v-1.33Zm28 2.66H0v1.34h28v-1.34ZM0 16h28v1.33H0V16Zm28 2.67H0V20h28v-1.33Z"
      />
      <path fill="#46467F" d="M0 0h12v9.33H0z" />
      <path
        fill="#fff"
        d="M2.67 2a.67.67 0 1 1-1.34 0 .67.67 0 0 1 1.34 0Zm2.66 0A.67.67 0 1 1 4 2a.67.67 0 0 1 1.33 0Zm2 .67a.67.67 0 1 0 0-1.34.67.67 0 0 0 0 1.34ZM10.67 2a.67.67 0 1 1-1.34 0 .67.67 0 0 1 1.34 0ZM3.33 4a.67.67 0 1 0 0-1.33.67.67 0 0 0 0 1.33Zm3.34-.67a.67.67 0 1 1-1.34 0 .67.67 0 0 1 1.34 0Zm2 .67a.67.67 0 1 0 0-1.33.67.67 0 0 0 0 1.33Zm2 .67a.67.67 0 1 1-1.34 0 .67.67 0 0 1 1.34 0Zm-3.34.66a.67.67 0 1 0 0-1.33.67.67 0 0 0 0 1.33Zm-2-.66a.67.67 0 1 1-1.33 0 .67.67 0 0 1 1.33 0ZM2 5.33A.67.67 0 1 0 2 4a.67.67 0 0 0 0 1.33ZM4 6a.67.67 0 1 1-1.33 0A.67.67 0 0 1 4 6Zm2 .67a.67.67 0 1 0 0-1.34.67.67 0 0 0 0 1.34ZM9.33 6A.67.67 0 1 1 8 6a.67.67 0 0 1 1.33 0ZM10 8a.67.67 0 1 0 0-1.33A.67.67 0 0 0 10 8Zm-2-.67a.67.67 0 1 1-1.33 0 .67.67 0 0 1 1.33 0ZM4.67 8a.67.67 0 1 0 0-1.33.67.67 0 0 0 0 1.33Zm-2-.67a.67.67 0 1 1-1.34 0 .67.67 0 0 1 1.34 0Z"
      />
    </svg>
  ),
  de: (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
      <path fill="#262626" d="M-4 6.67h28V0H-4v6.67Z" />
      <path fill="#F01515" d="M-4 13.33h28V6.67H-4v6.66Z" />
      <path fill="#FFD521" d="M-4 20h28v-6.67H-4V20Z" />
    </svg>
  ),
  es: (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
      <path fill="#DD172C" d="M0 5.33h28V0H0v5.33ZM0 20h28v-5.33H0V20Z" />
      <path fill="#FFD133" d="M0 14.67h28V5.33H0v9.34Z" />
      <path fill="#FFEDB1" d="M7.33 9.33h1.34V10H7.33v-.67Z" />
      <path
        stroke="#A41517"
        strokeWidth=".67"
        d="M6.06 9.36A.33.33 0 0 1 6.39 9h1.89c.2 0 .34.17.33.36l-.17 2.06a1 1 0 0 1-1 .91h-.21a1 1 0 0 1-1-.91l-.17-2.06Z"
      />
      <path fill="#A41517" d="M6 10h2.67v.67H8L7.33 12l-.66-1.33H6V10Z" />
      <rect width="1.33" height="4.67" x="4" y="8" fill="#A41517" rx=".67" />
      <rect width="1.33" height="4.67" x="9.33" y="8" fill="#A41517" rx=".67" />
      <path
        fill="#A41517"
        d="M6 7.73c0-.59.48-1.06 1.07-1.06h.53c.59 0 1.07.47 1.07 1.06 0 .15-.12.27-.27.27H6.27A.27.27 0 0 1 6 7.73Z"
      />
    </svg>
  ),
  tr: (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
      <rect width="28" height="20" fill="#fff" rx="2" />
      <path fill="#E92434" d="M0 0h28v20H0z" />
      <path
        fill="#fff"
        d="m19.02 10.77-1.22 1.48.08-1.92-1.8-.7 1.86-.52.12-1.92 1.06 1.6L21 8.31l-1.2 1.5 1.03 1.63-1.8-.67Z"
      />
      <path
        fill="#fff"
        d="M17.4 13.68a5.99 5.99 0 1 1 0-7.36 5.2 5.2 0 0 0-3.07-.99c-2.76 0-5 2.1-5 4.67 0 2.58 2.24 4.67 5 4.67a5.2 5.2 0 0 0 3.07-.99Z"
      />
    </svg>
  ),
  pt: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4264_903" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#43ADA8"
        />
      </mask>
      <g>
        <rect x="-4" width="28" height="20" rx="2" fill="white" />
        <mask id="mask1_4264_903" maskUnits="userSpaceOnUse" x="-4" y="0" width="28" height="20">
          <rect x="-4" width="28" height="20" rx="2" fill="white" />
        </mask>
        <g>
          <rect x="-4" width="28" height="20" fill="#FF2936" />
          <path fillRule="evenodd" clipRule="evenodd" d="M-4 20H6.66667V0H-4V20Z" fill="#128415" />
          <path
            d="M6.66667 13.3333C8.50762 13.3333 10 11.8409 10 10C10 8.15905 8.50762 6.66667 6.66667 6.66667C4.82572 6.66667 3.33333 8.15905 3.33333 10C3.33333 11.8409 4.82572 13.3333 6.66667 13.3333Z"
            stroke="#FAF94F"
            strokeWidth="1.33333"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33301 8.33333C5.33301 8.14924 5.48225 8 5.66634 8H7.66634C7.85044 8 7.99967 8.14924 7.99967 8.33333V10.6667C7.99967 11.403 7.40272 12 6.66634 12C5.92996 12 5.33301 11.403 5.33301 10.6667V8.33333Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66667 10.6667C7.03486 10.6667 7.33333 9.70152 7.33333 9.33333C7.33333 8.96514 7.03486 8.66667 6.66667 8.66667C6.29848 8.66667 6 8.96514 6 9.33333C6 9.70152 6.29848 10.6667 6.66667 10.6667Z"
            fill="#1D50B5"
          />
        </g>
      </g>
    </svg>
  ),
  hr: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4264_891" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#43ADA8"
        />
      </mask>
      <g>
        <rect
          x="-3.75"
          y="0.25"
          width="27.5"
          height="19.5"
          rx="1.75"
          fill="white"
          stroke="#F5F5F5"
          strokeWidth="0.5"
        />
        <mask id="mask1_4264_891" maskUnits="userSpaceOnUse" x="-4" y="0" width="28" height="20">
          <rect
            x="-3.75"
            y="0.25"
            width="27.5"
            height="19.5"
            rx="1.75"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
          />
        </mask>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-4 6.66667H24V0H-4V6.66667Z"
            fill="#FF202D"
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66699 5.33333L7.33366 4.66666L8.00033 5.33333V6.66666H6.66699V5.33333ZM9.33366 5.33333L10.0003 4.66666L10.667 5.33333V6.66666H9.33366V5.33333ZM12.667 4.66666L12.0003 5.33333V6.66666H13.3337V5.33333L12.667 4.66666Z"
            fill="#1895DB"
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.66667 4.66666L8 5.33333V6.66666H9.33333V5.33333L8.66667 4.66666ZM11.3333 4.66666L10.6667 5.33333V6.66666H12V5.33333L11.3333 4.66666Z"
            fill="#191F94"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M-4 20H24V13.3333H-4V20Z" fill="#2027AC" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00033 6.66666H7.33366L6.66699 8H8.00033V9.33333H6.66699V10.6667H8.00033V12H6.66699V13.3333H8.00033V14.6667H9.33366V13.3333H10.667V14.6667H12.0003V13.3333H13.3337V12H12.0003V10.6667H13.3337V9.33333H12.0003V8H13.3337L12.667 6.66666H12.0003V8H10.667V6.66666H9.33366V8H8.00033V6.66666ZM9.33366 9.33333V8H10.667V9.33333H9.33366ZM9.33366 10.6667V9.33333H8.00033V10.6667H9.33366ZM10.667 10.6667H9.33366V12H8.00033V13.3333H9.33366V12H10.667V13.3333H12.0003V12H10.667V10.6667ZM10.667 10.6667H12.0003V9.33333H10.667V10.6667Z"
            fill="url(#paint0_linear_4264_891)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4264_891"
          x1="6.66699"
          y1="6.66666"
          x2="6.66699"
          y2="14.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF212E" />
          <stop offset="1" stopColor="#FD0D1B" />
        </linearGradient>
      </defs>
    </svg>
  ),
  sq: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4264_897" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#43ADA8"
        />
      </mask>
      <g>
        <mask id="mask1_4264_897" maskUnits="userSpaceOnUse" x="-4" y="0" width="28" height="20">
          <rect x="-4" width="28" height="20" rx="2" fill="white" />
        </mask>
        <g>
          <rect x="-4" width="28" height="20" fill="#EE343C" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.38613 4.38646C8.16142 4.16175 7.80934 4.12689 7.54493 4.30316L6.95552 4.6961C6.52961 4.98004 6.56934 5.61817 7.02718 5.84709L8.29782 6.4824C8.52367 6.59533 8.66634 6.82617 8.66634 7.07869V7.33333C8.66634 7.70152 8.36786 8 7.99967 8H7.49039C7.38689 8 7.28481 7.9759 7.19224 7.92962L5.09612 6.88156C4.83946 6.75323 4.52948 6.80353 4.32657 7.00644L3.98705 7.34596C3.66529 7.66772 3.75331 8.21015 4.16031 8.41365L5.10425 8.88562C5.50324 9.08511 5.53786 9.64121 5.1667 9.88865C4.73604 10.1758 4.86748 10.8402 5.37502 10.9417L5.93696 11.0541C6.4765 11.162 6.63423 11.8574 6.19405 12.1875L5.69096 12.5649C5.36274 12.811 5.53684 13.3333 5.94712 13.3333C5.98202 13.3333 6.0168 13.3291 6.05066 13.3206L7.31244 13.0051C7.79087 12.8855 8.16577 13.4175 7.89222 13.8278C7.65318 14.1864 7.91022 14.6667 8.34114 14.6667H8.92462C9.16851 14.6667 9.38503 14.8227 9.46215 15.0541C9.63434 15.5707 10.365 15.5707 10.5372 15.0541C10.6143 14.8227 10.8308 14.6667 11.0747 14.6667H11.6582C12.0891 14.6667 12.3462 14.1864 12.1071 13.8278C11.8336 13.4175 12.2085 12.8855 12.6869 13.0051L13.9487 13.3206C13.9826 13.3291 14.0173 13.3333 14.0522 13.3333C14.4625 13.3333 14.6366 12.811 14.3084 12.5649L13.8053 12.1875C13.3651 11.8574 13.5228 11.162 14.0624 11.0541L14.6243 10.9417C15.1319 10.8402 15.2633 10.1758 14.8327 9.88865C14.4615 9.64121 14.4961 9.08511 14.8951 8.88562L15.839 8.41365C16.246 8.21015 16.3341 7.66772 16.0123 7.34596L15.6728 7.00644C15.4699 6.80353 15.1599 6.75323 14.9032 6.88156L12.8071 7.92962C12.7145 7.9759 12.6125 8 12.509 8H11.9997C11.6315 8 11.333 7.70152 11.333 7.33333V7.07869C11.333 6.82617 11.4757 6.59534 11.7015 6.48242C12.0941 6.28612 12.5618 6.05229 12.9724 5.84697C13.4302 5.61805 13.4697 4.98004 13.0438 4.6961L12.4544 4.30316C12.19 4.12689 11.8379 4.16175 11.6132 4.38646L10.4711 5.5286C10.2107 5.78895 9.78862 5.78894 9.52827 5.5286L8.38613 4.38646Z"
            fill="#262626"
          />
        </g>
      </g>
    </svg>
  ),
  sr: (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
      <rect
        width="27.5"
        height="19.5"
        x=".25"
        y=".25"
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth=".5"
        rx="1.75"
      />
      <g>
        <path fill="#17508F" fillRule="evenodd" d="M0 13.33h28V6.66H0v6.67Z" clipRule="evenodd" />
        <path fill="#E1444D" fillRule="evenodd" d="M0 6.67h28V0H0v6.67Z" clipRule="evenodd" />
        <path
          fill="red"
          fillRule="evenodd"
          d="M6 10c0-.65.34-2.08.54-2.84.07-.3.33-.5.64-.5h2.98c.3 0 .56.2.64.5.19.75.53 2.18.53 2.84 0 .74-.5 2.7-.63 3.2a.57.57 0 0 1-.11.23c-.26.31-1.1 1.23-1.92 1.23-.84 0-1.67-.92-1.92-1.23a.57.57 0 0 1-.12-.22A19.7 19.7 0 0 1 6 10Z"
          clipRule="evenodd"
        />
        <mask id="lang-sr-mask1" width="6" height="9" x="6" y="6" maskUnits="userSpaceOnUse">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M6 10c0-.65.34-2.08.54-2.84.07-.3.33-.5.64-.5h2.98c.3 0 .56.2.64.5.19.75.53 2.18.53 2.84 0 .74-.5 2.7-.63 3.2a.57.57 0 0 1-.11.23c-.26.31-1.1 1.23-1.92 1.23-.84 0-1.67-.92-1.92-1.23a.57.57 0 0 1-.12-.22A19.7 19.7 0 0 1 6 10Z"
            clipRule="evenodd"
          />
        </mask>
        <g stroke="#fff" strokeLinecap="square" strokeWidth="1.33" mask="url(#lang-sr-mask1)">
          <path d="M11.33 7.33 6 14M6 7.33 11.33 14" />
        </g>
      </g>
    </svg>
  ),
  "sr-en": (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
      <rect
        width="27.5"
        height="19.5"
        x=".25"
        y=".25"
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth=".5"
        rx="1.75"
      />
      <g>
        <path fill="#17508F" fillRule="evenodd" d="M0 13.33h28V6.66H0v6.67Z" clipRule="evenodd" />
        <path fill="#E1444D" fillRule="evenodd" d="M0 6.67h28V0H0v6.67Z" clipRule="evenodd" />
        <path
          fill="red"
          fillRule="evenodd"
          d="M6 10c0-.65.34-2.08.54-2.84.07-.3.33-.5.64-.5h2.98c.3 0 .56.2.64.5.19.75.53 2.18.53 2.84 0 .74-.5 2.7-.63 3.2a.57.57 0 0 1-.11.23c-.26.31-1.1 1.23-1.92 1.23-.84 0-1.67-.92-1.92-1.23a.57.57 0 0 1-.12-.22A19.7 19.7 0 0 1 6 10Z"
          clipRule="evenodd"
        />
        <mask id="lang-sr-mask1" width="6" height="9" x="6" y="6" maskUnits="userSpaceOnUse">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M6 10c0-.65.34-2.08.54-2.84.07-.3.33-.5.64-.5h2.98c.3 0 .56.2.64.5.19.75.53 2.18.53 2.84 0 .74-.5 2.7-.63 3.2a.57.57 0 0 1-.11.23c-.26.31-1.1 1.23-1.92 1.23-.84 0-1.67-.92-1.92-1.23a.57.57 0 0 1-.12-.22A19.7 19.7 0 0 1 6 10Z"
            clipRule="evenodd"
          />
        </mask>
        <g stroke="#fff" strokeLinecap="square" strokeWidth="1.33" mask="url(#lang-sr-mask1)">
          <path d="M11.33 7.33 6 14M6 7.33 11.33 14" />
        </g>
      </g>
    </svg>
  ),
  el: (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
      <rect width="27.5" height="19.5" x=".25" y=".25" fill="#fff" stroke="#fff" strokeWidth=".5" />
      <path
        fill="#1c6dc1"
        d="M5.33 0H0v5.33h5.33V0Zm8 0H8v5.33h5.33V8H8v5.33h20v-2.66H13.33V8H28V5.33H13.33V2.67H28V0H13.33ZM28 16H0v2.67h28V16ZM5.33 8H0v5.33h5.33V8Z"
      />
    </svg>
  ),
  zh: (
    <svg width="28" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="20" rx="2" fill="white" />
      <mask id="mask0_339_2975" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
        <rect width="28" height="20" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_339_2975)">
        <rect width="28" height="20" fill="#F1361D" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0728 3.10054L11.0412 3.26565L11.7675 3.92714L11.9326 2.9587L12.5941 2.23236L11.6257 2.06726L10.8994 1.40576L10.7342 2.37421L10.0728 3.10054ZM12.6668 6.48782L13.506 5.97707L14.4881 5.99979L13.9774 5.16057L14.0001 4.17842L13.1609 4.68917L12.1787 4.66645L12.6895 5.50567L12.6668 6.48782ZM11.5284 11.9707L10.7075 12.5104L10.6959 11.528L10.1562 10.7072L11.1385 10.6956L11.9594 10.1559L11.971 11.1382L12.5107 11.9591L11.5284 11.9707ZM12.2412 9.43122L13.2177 9.32299L14.0982 9.75866L13.99 8.78222L14.4256 7.90168L13.4492 8.00991L12.5687 7.57425L12.6769 8.55069L12.2412 9.43122Z"
          fill="#FFDC42"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66675 8.2265L4.31561 9.90257L5.1831 7.14857L2.86252 5.43044L5.7498 5.40444L6.66675 2.6665L7.58369 5.40444L10.471 5.43044L8.1504 7.14857L9.01789 9.90257L6.66675 8.2265Z"
          fill="#FFDC42"
        />
      </g>
    </svg>
  ),
  ru: (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.25"
        y="0.25"
        width="27.5"
        height="19.5"
        rx="1.75"
        fill="white"
        stroke="#F5F5F5"
        stroke-width="0.5"
      />
      <mask id="mask0_339_4070" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
        <rect
          x="0.25"
          y="0.25"
          width="27.5"
          height="19.5"
          rx="1.75"
          fill="white"
          stroke="white"
          stroke-width="0.5"
        />
      </mask>
      <g mask="url(#mask0_339_4070)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13.3332H28V6.6665H0V13.3332Z"
          fill="#0C47B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 20.0002H28V13.3335H0V20.0002Z"
          fill="#E53B35"
        />
      </g>
    </svg>
  ),
  ro: (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="20" rx="2" fill="white" />
      <mask id="mask0_339_4302" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
        <rect width="28" height="20" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_339_4302)">
        <rect x="13.3333" width="14.6667" height="20" fill="#DE1D42" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 20H9.33333V0H0V20Z" fill="#083780" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33325 20H18.6666V0H9.33325V20Z"
          fill="#FFD147"
        />
      </g>
    </svg>
  ),
};
