import { memo } from "react";

import { FortuneWheel } from "@entities/fortune-wheel";
import { formatTime, useFortuneWheelCountdown } from "@shared/lib";

import { WHEEL_RADIUS as R } from "../lib";

export const Timer = memo(function Timer() {
  const { data } = FortuneWheel.useWheelState();

  const countdown = useFortuneWheelCountdown(data?.nextSpinTime as number);

  return (
    <text
      className="font-commissioner fill-java text-2xl font-extrabold [text-shadow:0_0_24px_#13c7bc]"
      alignmentBaseline="middle"
      textAnchor="middle"
      x={R}
      y={R}
    >
      {formatTime(countdown)}
    </text>
  );
});
