import { CategoryIcon } from "@features/category/category-icon";

import { useNavigateToCategory } from "../lib/use-navigate-to-category";

interface ICategoryButtonProps {
  children: React.ReactNode;
  isActive: boolean;
  disabled?: boolean;
  id: string | number;
}

export function CategoryButton({ children, isActive, disabled, id }: ICategoryButtonProps) {
  const navigate = useNavigateToCategory();

  return (
    <button
      className={`flex h-12.5 w-full select-none flex-col items-center justify-center gap-1 rounded-sm px-5 font-bold text-2xs uppercase active:text-white disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-mercury disabled:text-dusty-gray lg:h-15 lg:flex-row lg:justify-start lg:gap-2.5 lg:rounded-rounded lg:px-7.5 lg:text-base dark:bg-bright-gray dark:disabled:bg-ebony-clay dark:disabled:text-bright-gray dark:active:bg-java ${isActive ? "bg-keppel text-white dark:bg-java" : "bg-white active:bg-keppel"}`}
      type="button"
      disabled={disabled}
      onClick={() => navigate(id)}
    >
      <CategoryIcon id={id} />
      <span className="truncate">{children}</span>
    </button>
  );
}
