import { useCallback, useState } from "react";
import { Client as WebSocket } from "rpc-websockets";

import { _normalize, _normalizeSlots, _normalizeWin } from "../helpers";

import type { ISlot, IWin } from "./types";

const ERROR_NOT_CONNECTED = "WEBSOCKET ERROR";
export const useRpcWebsocket = () => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const init = useCallback(async (streamId: string | null) => {
    if (!streamId) {
      return;
    }

    let fullUrl = "";

    const pathname = `/ws/?stream=${streamId}`;

    if (window.location.hostname === "localhost") {
      fullUrl = import.meta.env.VITE_WSS_URL + pathname;
    } else {
      const url = `${window.location.host}${pathname}`;
      fullUrl = window.location.protocol === "https:" ? `wss://${url}` : `ws://${url}`;
    }

    const newWs = new WebSocket(fullUrl);
    setWs(newWs);

    const handleOpen = () => {
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const handleError = (error: Error) => {
      setIsOpen(false);
      console.error(error.message);
    };

    newWs.on("open", handleOpen);
    newWs.on("close", handleClose);
    newWs.on("error", handleError);
  }, []);

  const call = async (event: string, params?: any): Promise<unknown> => {
    if (!isOpen || !ws) {
      throw new Error(ERROR_NOT_CONNECTED);
    }

    return ws.call(event, params);
  };

  const on = (event: string, listener: any): void => {
    if (!isOpen || !ws) {
      throw new Error(ERROR_NOT_CONNECTED);
    }

    ws.on(event, listener);
  };

  return {
    error,
    init,
    ws,
    isOpen,
    connect: () =>
      new Promise<void>((resolve, reject) => {
        ws?.on("open", () => {
          console.log("SOCKET OPEN");
          setIsOpen(true);
          resolve();
        });

        ws?.on("close", () => {
          console.log("SOCKET CLOSE");
          setIsOpen(false);
        });

        ws?.on("error", (error) => {
          console.log("SOCKET ERROR", error.message);
          setIsOpen(false);
          setError(error?.message);
          reject(error);
        });
      }),
    handleGetSlotsAndWins: async (): Promise<{
      slots: ISlot[] | null;
      wins: IWin[] | null;
    }> => {
      const response = await call("Jackpot.GetState");
      return _normalize(response);
    },
    addSlotsListener: (listener: (slots: ISlot[] | null) => void): void => {
      on("Jackpot.UpdateSlots", (response: any) => {
        listener(_normalizeSlots(response?.updates));
      });
    },
    addWinsListener: (listener: (win: IWin | null) => void): void => {
      on("Jackpot.WinStatus", (response: any) => {
        listener(_normalizeWin(response?.win));
      });
    },
    acceptWin: async (winId: number): Promise<void> => {
      await call("Jackpot.AcceptWin", { win_id: winId });
    },
  };
};
