import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { clsx } from "clsx";

import { usePlayerMoneyFormatter } from "@entities/player";

import { formatOdds } from "../lib/helpers";

import type { ApiClientResponse } from "@lobby/api-client";
import type { PropsWithChildren } from "react";

import "./styles.css";

type TCoupon = ApiClientResponse<"History.getSport">["result"]["data"][number];

export function Coupon({
  createdAt,
  uid,
  eventsToGuessNum,
  description,
  typeEx,
  odds,
  bet,
  win,
  currency,
  status,
  bonusAmount = 0,
  outcomeStatus,
  events,
}: Omit<TCoupon, "type">) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  const isCancelled = status === "cancelled" || outcomeStatus === "cancelled";
  const isWin = outcomeStatus === "win";
  const isHalfWin = outcomeStatus === "half_win";
  const isHalfLost = outcomeStatus === "half_lost";
  const isDraw = outcomeStatus === "draw";
  const isLost = outcomeStatus === "lost";

  const hasBonus = bonusAmount > 0;

  const formattedDate = new Date(createdAt).toLocaleDateString();
  const formattedUid = uid?.match(/.{1,4}/g)?.join(" ") ?? uid;
  const formattedOdds = formatOdds(odds);
  const formattedBonus = formatMoney(bonusAmount, { currency });
  const formattedBet = formatMoney(bet, { currency });
  const formattedWin = formatMoney(win, { currency });

  let BottomRightStatus: React.ReactNode = <></>;
  if (hasBonus && (isWin || isHalfWin || isHalfLost || isDraw)) {
    BottomRightStatus = (
      <div className="br font-bold self-end">
        <span className="font-light">{$t({ defaultMessage: "Bonus" }) + skipIntl(": ")}</span>
        <span>{formattedBonus}</span>
      </div>
    );
  } else if (status === "cashout") {
    BottomRightStatus = (
      <div className="lg:text-sm text-2xs br font-bold uppercase self-end">
        {$t({ defaultMessage: "cashout" })}
      </div>
    );
  } else if (isCancelled) {
    BottomRightStatus = (
      <div className="lg:text-sm text-2xs tr font-bold uppercase self-end">
        {$t({ defaultMessage: "cancelled" })}
      </div>
    );
  }

  return (
    <div className="coupon">
      <div
        className={`lg:px-5 lg:py-2.5 px-3 py-1.5 ${isWin ? "bg-keppel dark:bg-java" : "bg-athens-gray dark:bg-outer-space"}`}
      >
        <div className="flex-c-sb font-bold uppercase">
          <div className="whitespace-pre">
            {formattedDate} {typeEx === "single" ? typeEx : description}
          </div>
          {eventsToGuessNum > 1 && <div>{formattedOdds}</div>}
        </div>
      </div>
      {events.map((ev, idx) => {
        const isWinEvent = ev.status === "win";
        const isLoseEvent = ev.status === "lose";

        return (
          <div
            className={clsx(
              "lg:px-5 lg:py-2.5 lg:gap-2.5 gap-1.5 px-3 py-1.5 bg-white dark:bg-bright-gray flex flex-col overflow-hidden",
              idx > 0 &&
                "border-dashed border-t-2 border-mercury rounded-t-xl dark:border-ebony-clay",
              events.length > 1 && idx !== events.length - 1 && "rounded-b-xl",
              `coupon-status_${isLoseEvent ? "lost" : ev.status}`,
            )}
            key={idx}
          >
            <div className="flex-c-sb">
              <div className="font-light">{ev.name}</div>
              <div className="font-bold">{formatOdds(ev.odds)}</div>
            </div>
            <div className="flex-c-sb font-bold">
              <div className="text-keppel dark:text-java">{ev.sport}</div>
              <div>{ev.categoryName}</div>
            </div>
            <div className="flex-c-sb">
              <div>
                <span className="font-bold">{ev.selName}</span>
                {skipIntl(" ")}
                <span className="font-light">{skipIntl(`(${ev.marketName})`)}</span>
              </div>
              <div className="lg:size-5.5 size-4">
                {isWinEvent ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 22 23"
                  >
                    <path
                      className="fill-keppel dark:fill-java"
                      fillRule="evenodd"
                      d="M11 22.904c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11Zm-.869-4.759 8.108-9.266-2.258-1.975-5.893 6.734L6.22 9.383 4 11.4l6.131 6.744Z"
                    />
                  </svg>
                ) : (
                  isLoseEvent && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      viewBox="0 0 22 23"
                    >
                      <path
                        className="fill-radical-red"
                        fillRule="evenodd"
                        d="M11 22.904c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11ZM16.85 8.56l-3.535 3.535 3.535 3.536-2.121 2.121-3.536-3.536-3.535 3.536-2.122-2.121 3.536-3.536-3.535-3.535 2.12-2.122 3.536 3.536 3.536-3.536 2.121 2.122Z"
                      />
                    </svg>
                  )
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div
        className={clsx(
          "lg:px-5 bg-white px-3 dark:bg-bright-gray status-win",
          `coupon-status_${outcomeStatus}`,
        )}
      >
        <hr className="text-blue-bayoux border-1" />
        <div className="coupon-footer">
          {/* TOP LEFT */}
          <div className="tl font-bold whitespace-pre">
            <span className="font-light">{$t({ defaultMessage: "Bet" }) + skipIntl(": ")}</span>
            <span>{formattedBet}</span>
          </div>

          {/* TOP RIGHT */}
          {status === "cashout" ? (
            <OutcomeStatus>
              <span>{formattedWin}</span>
            </OutcomeStatus>
          ) : (
            <>
              {isWin && (
                <OutcomeStatus>
                  <span className="font-light">
                    {$t({ defaultMessage: "Win" }) + skipIntl(": ")}
                  </span>
                  <span>{formattedWin}</span>
                  <WinArrow />
                </OutcomeStatus>
              )}
              {isHalfWin && (
                <OutcomeStatus>
                  <span className="font-light">
                    {$t({ defaultMessage: "Half win" }) + skipIntl(": ")}
                  </span>
                  <span>{formattedWin}</span>
                  <WinArrow />
                </OutcomeStatus>
              )}
              {isHalfLost && (
                <OutcomeStatus>
                  <span className="font-light">
                    {$t({ defaultMessage: "Half lose" }) + skipIntl(": ")}
                  </span>
                  <span>{formattedWin}</span>
                  <WinArrow />
                </OutcomeStatus>
              )}
              {isDraw && (
                <OutcomeStatus>
                  <span className="font-light">{skipIntl("Draw: ")}</span>
                  <span>{formattedWin}</span>
                </OutcomeStatus>
              )}
              {isLost && <OutcomeStatus>{$t({ defaultMessage: "Lose" })}</OutcomeStatus>}
            </>
          )}

          {/* BOTTOM LEFT */}
          <div className="lg:text-2xs text-3xs bl text-dusty-gray self-end">
            {$t({ defaultMessage: "ID" }) + skipIntl(": ")} {formattedUid}
          </div>

          {/* BOTTOM RIGHT */}
          {BottomRightStatus}
        </div>
      </div>
    </div>
  );
}

function OutcomeStatus({ children }: PropsWithChildren) {
  return (
    <div className="tr flex items-center justify-end font-bold whitespace-pre">{children}</div>
  );
}

function WinArrow() {
  return (
    <svg className="lg:size-3.5 size-3 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15">
      <path
        className="fill-keppel dark:fill-java"
        d="M14 8.275 7.368.905.734 8.275h3.683v6.628h5.896V8.275h3.688Z"
      />
    </svg>
  );
}
