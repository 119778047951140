import { apiClient } from "@lobby/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useAuth } from "@shared/lib";

import type { SendOptions } from "@lobby/api-client";

export const Payment = {
  useDeposit() {
    return useMutation({
      mutationKey: ["Payment.deposit"],
      mutationFn: (params: SendOptions<"Payment.deposit">["params"]) =>
        apiClient.send<"Payment.deposit">({
          endpoint: "Payment.deposit",
          params,
        }),
    });
  },
  useWithdraw() {
    return useMutation({
      mutationKey: ["Payment.withdraw"],
      mutationFn: (params: SendOptions<"Payment.withdraw">["params"]) =>
        apiClient.send<"Payment.withdraw">({
          endpoint: "Payment.withdraw",
          params,
        }),
    });
  },
  useMethodList(paymentType: "deposit" | "withdraw") {
    const { isAuth } = useAuth();

    return useQuery({
      enabled: isAuth,
      queryKey: ["Payment.getMethodsList", paymentType],
      queryFn: () =>
        apiClient.send({
          endpoint: "Payment.getMethodsList",
          params: {
            paymentType,
          },
        }),
      select: (data) => data.result.data,
    });
  },
};
