import { useModal } from "@shared/lib";
import { LogInModal } from "@widget/log-in-modal";
import { PasswordForgotModal } from "@widget/password-forgot-modal";
import { RegisterModal } from "@widget/register-modal";
import { useEffect } from "react";

export function SignInPage() {
  const { open: openLogInModal } = useModal("log-in");

  useEffect(() => openLogInModal(), [openLogInModal]);

  return (
    <div className="fixed inset-0">
      <LogInModal isStatic />
      <RegisterModal isStatic />
      <PasswordForgotModal isStatic />
    </div>
  );
}
