import { apiClient } from "@lobby/api-client";
import { useQuery } from "@tanstack/react-query";

export const SocialNetwork = {
  useAuthEndpoints() {
    return useQuery({
      queryKey: ["SocialNetwork.getAuthEndpoints"],
      queryFn: () =>
        apiClient.send<"SocialNetwork.getAuthEndpoints">({
          endpoint: "SocialNetwork.getAuthEndpoints",
        }),
    });
  },
};
