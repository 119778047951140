import { useMemo } from "react";

import { uniqId } from "@shared/lib";

interface IJackpotDecoratorProps {
  className?: string;
  gradient?: [string, string];
}

export function JackpotDecorator({ className, gradient }: IJackpotDecoratorProps) {
  const id = useMemo(uniqId, []);

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 58">
      <path
        fill={`url(#${id}`}
        fillRule="evenodd"
        d="M18.42 49.77c1.68-.34 3.3-.96 4.8-1.89C23.72 47.57 25 46 25 46s3.33-.34 5.1-1.44c2.33-1.43 4.98-3.8 5.94-6.03.03-.1-.07-.19-.15-.14-1 .5-2.13.53-2.85.3h-.02a10.13 10.13 0 0 1-2.81-1.16l.1-.57a7.92 7.92 0 0 1 2.54-.25h.67c1.83-.03 4.05-1.42 4.75-2.9l.25-.55c.18-.39.35-.78.55-1.15l.3-.6c.5-1.06 1.12-2.33 2.19-2.65.08-.03.09-.15 0-.2a3.06 3.06 0 0 0-2.5-.19 7.3 7.3 0 0 0-1.95 1.25l-.38.3c-1.3 1.03-2.98.87-4.6.24-.1-.04-.08-.18.02-.2a5.62 5.62 0 0 0 4.46-3.79c.12-.4.29-1.09.44-1.74.13-.55.26-1.07.36-1.42 1.24-4.38 5.18-6.58 9.02-7.08.1-.01.12-.15.02-.2-4.1-.8-6.21-1.5-7.59-1.97-1.9-.63-2.38-.79-4.76-.1-1.42.42-2.37 1.14-3.25 1.8-1.64 1.25-3 2.28-6.68.64 1.16-.76 1.86-2.27 2.17-3.76.03-.13-.09-.25-.2-.2-.99.5-1.74.81-3.08.51-.66-.14-1.3-.34-1.95-.54-1-.32-2.01-.63-3.04-.73-.58-.06-1.33.22-2.04.5-.5.18-.96.35-1.34.4-.81-.18-1.69-.32-2.6-.4a21.94 21.94 0 0 1 3.8-2.2 28.28 28.28 0 0 1 5.9-4.5c2.99-1.72 7.12-3.35 10.04-3.33.11 0 .14.14.04.21a5.52 5.52 0 0 0-2.17 2.76l-.01.02c-.42 1.4-.63 2.12-.65 3.37l.65.14c.7-.59 1.28-1.48 1.92-2.47l.43-.67c1.22-1.84 4.09-3.48 6.06-3.53l.72-.01a30.46 30.46 0 0 0 2.34-.08c1.4-.05 3.1-.1 4.13-1.05.09-.07.22-.02.2.08a3.89 3.89 0 0 1-1.43 2.62 11 11 0 0 1-2.55 1.43l-.56.25c-1.9.87-2.83 2.64-3.23 4.55-.03.12.13.18.21.08 1.69-1.9 4.32-3.12 6.77-2.84.5.06 1.3.2 2.07.33.65.1 1.27.21 1.68.26 5.28.69 10.1-2.32 13.12-6 .08-.1.22-.05.21.06-.46 4.96-2.05 9.82-5.95 13.46-1.34 1.25-2.7 1.9-3.95 2.49-2.34 1.12-4.29 2.04-5 6.5 1.52-.84 3.52-.88 5.24-.54.16.03.2.2.07.3-1.15.77-1.96 1.4-2.52 2.9-.28.72-.5 1.47-.72 2.22-.34 1.15-.67 2.31-1.24 3.4-.32.62-1.1 1.25-1.84 1.86-.5.4-.99.8-1.3 1.17-1.57 4.23-4.97 9.32-10.2 12.35a21.74 21.74 0 0 1-15.95 2.2Z"
        clipRule="evenodd"
      />
      <defs>
        {gradient ? (
          <linearGradient id={`${id}`}>
            <stop stopColor={gradient[0]} />
            <stop offset="1" stopColor={gradient[1]} />
          </linearGradient>
        ) : (
          <linearGradient id={`${id}`}>
            <stop stopColor="currentColor" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
        )}
      </defs>
    </svg>
  );
}
