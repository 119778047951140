export class NumberUtilities {
  static parseInteger(value: unknown): number | undefined {
    if (typeof value === "number") {
      if (!this.isSafeInteger(value)) {
        return undefined;
      }
      return value;
    }

    if (typeof value !== "string") {
      return undefined;
    }

    if (!/^-?\d+$/.test(value)) {
      return undefined;
    }

    return this.parseInteger(Number.parseInt(value, 10));
  }

  static isFinite(value: unknown): value is number {
    return Number.isFinite(value);
  }

  static isSafeInteger(value: unknown): value is number {
    return Number.isSafeInteger(value);
  }

  static parseFloat(value: unknown): number | undefined {
    if (typeof value === "number") {
      return Number.isFinite(value) ? value : undefined;
    }

    if (typeof value !== "string") {
      return undefined;
    }

    return Number.parseFloat(value);
  }
}
