import { apiClient } from "@lobby/api-client";
import { useMutation } from "@tanstack/react-query";

import type { SendOptions } from "@lobby/api-client";

export const Email = {
  useSetNewPassword() {
    return useMutation({
      mutationKey: ["Email.setNewPassword"],
      mutationFn: (params: SendOptions<"Email.setNewPassword">["params"]) =>
        apiClient.send<"Email.setNewPassword">({
          endpoint: "Email.setNewPassword",
          params,
        }),
      onSuccess: (data) => {
        if (data.error) {
          console.error(data.error);
        }
      },
    });
  },
};
