import { clsx } from "clsx";

import { useModal } from "@shared/lib";

export function MobileMenuButton({ className }: { className?: string }) {
  const { open: openProfile } = useModal("profile");

  return (
    <div
      className={clsx(
        className,
        "flex flex-col gap-[0.1875rem] w-5 *:rounded-xs *:bg-keppel *:dark:bg-java *:h-[0.1875rem]",
      )}
      onClick={openProfile}
    >
      <div />
      <div />
      <div />
    </div>
  );
}
