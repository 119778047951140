import { LanguageSelector } from "@features/language-selector";
import { ThemeSwitch } from "@features/theme-switch";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, SVGIcon } from "@shared/ui";

export function ErrorComponent({ error }: { error: any }) {
  const { $t } = useTranslate();

  return (
    <div className="h-full flex-center gap-10">
      <div className="size-72 flex-center text-keppel dark:text-java">
        <SVGIcon name="gamepad" className="size-full" />
      </div>
      <div className="flex max-w-[30rem] flex-col items-start">
        {error?.code ? (
          <h1 className="font-bold text-6xl">{error.code}</h1>
        ) : (
          <h1 className="font-bold text-4xl">{$t({ defaultMessage: "Unknown error" })}</h1>
        )}
        <p className="my-5 text-lg">
          {$t({
            defaultMessage:
              "Sorry! Games are temporarily unavailable! Technical work is underway! We will fix everything soon.",
          })}
        </p>
        <Button type="primary" onClick={() => window.location.reload()}>
          {$t({ defaultMessage: "Reload page" })}
        </Button>
        <hr className="my-5 w-full text-shark dark:text-silver" />
        <div className="flex w-full items-center justify-between">
          <LanguageSelector orientation="top" />
          <ThemeSwitch />
        </div>
      </div>
    </div>
  );
}
