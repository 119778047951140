import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters/dist";

import { range } from "@shared/lib";
import { Skeleton } from "@shared/ui";

import "./style.css";

import { useNoWagerGames } from "./use-no-wager-games";

export function UnwagerGameList() {
  const { $t } = useTranslate();
  const { noJackpotGames, noWagerGames, isNoWagerLoading, isNoJackpotLoading } = useNoWagerGames();

  if (isNoJackpotLoading || isNoWagerLoading) {
    return range(10)((idx) => <Skeleton key={idx} className="bg-silver h-9 mb-2" />);
  }

  const UnWagerGameList = noWagerGames && (
    <div className="flex flex-col gap-2 mt-5">
      <p className="font-bold text-lg">
        {$t({
          defaultMessage: "A list of games in which the bonus cannot be wagered",
        })}
      </p>
      {Object.keys(noWagerGames || {}).map((providerName) => {
        return (
          <div key={providerName}>
            <span className="font-bold">
              {providerName}
              {skipIntl(":")}
            </span>
            <span className="comma">
              {noWagerGames?.[providerName]?.map((game) => (
                <span key={game.stringId} className="ml-2 game-name">
                  {game.name}
                </span>
              ))}
            </span>
          </div>
        );
      })}
    </div>
  );

  const NoJackpotGameList = noJackpotGames && (
    <div className="flex flex-col gap-2 mt-10">
      <p className="font-bold text-lg">
        {$t({
          defaultMessage:
            "List of games in which the jackpot does not accumulate and cannot be received",
        })}
      </p>
      {Object.keys(noJackpotGames || {}).map((providerName) => {
        return (
          <div key={providerName} className="">
            <span className="font-bold">
              {providerName} {skipIntl(":")}
            </span>
            <span className="comma">
              {noJackpotGames?.[providerName]?.map((game) => (
                <span key={game.stringId} className="ml-2 game-name">
                  {game.name}
                </span>
              ))}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="mobile-only:w-[calc(100%-2rem)]">
      {UnWagerGameList}
      {NoJackpotGameList}
    </div>
  );
}
