import { Skeleton } from "@shared/ui/skeleton";

export function GameCardSkeleton() {
  return (
    <div className="bg-white dark:bg-ebony-clay rounded-rounded h-full snap-start">
      <Skeleton className="h-[8.125rem]" />
      <div className="p-2.5">
        <Skeleton className="h-4" />
        <Skeleton className="mt-1 h-4" />
      </div>
    </div>
  );
}
