import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

import { Player, usePlayerMoneyFormatter } from "@entities/player";

export function ProfileBalanceDetails({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  const { data: data } = Player.useBalances();

  return (
    <div
      className={clsx(
        "lg:rounded-rounded lg:px-5 lg:text-sm bg-athens-gray rounded-xs p-3 text-blue-bayoux font-bold px-3 text-xs uppercase dark:bg-outer-space dark:text-dusty-gray",
        className,
      )}
    >
      <div className="flex-c-sb gap-2">
        <span className="whitespace-nowrap">{$t({ defaultMessage: "wager" })}</span>
        <span>{formatMoney(data?.wager?.remaining ?? 0)}</span>
      </div>
    </div>
  );
}
