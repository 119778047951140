import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { Fragment, type ReactNode } from "react";

export type TabsProps = {
  items: TabItemType[];
};

export type TabItemType = {
  label: ReactNode;
  children: ReactNode;
  disabled?: boolean;
};

export default function Tabs({ items }: TabsProps) {
  const { $t } = useTranslate();

  return (
    <TabGroup className="contents">
      <TabList className="grid grid-cols-[repeat(auto-fit,minmax(1px,1fr))] border-b-1 text-blue-bayoux">
        {items.map(({ label, disabled }, idx) => (
          <Tab as={Fragment} key={idx} disabled={disabled}>
            {({ selected }) => (
              <button
                className={`lg:border-b-3 lg:text-lg border-b-2 font-bold text-sm whitespace-normal focus-visible:outline-0
                  ${selected ? "border-current text-keppel dark:text-java" : "border-transparent"}
                  ${disabled ? "lg:px-3 opacity-50" : "lg:pt-2.5 lg:p-5 p-2"}`}
              >
                {disabled ? (
                  <div className="flex-center flex-col">
                    <span>{label}</span>
                    <span className="lg:text-2xs text-3xs">
                      {$t({ defaultMessage: "Not available for you" })}
                    </span>
                  </div>
                ) : (
                  label
                )}
              </button>
            )}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="lg:mt-7.5 mt-5 h-full *:h-full min-h-0">
        {items.map((item, idx) => (
          <TabPanel key={idx}>{item.children}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
}
